// useUserStore.tsx
import { User, UserPreferences } from '@/global-components/types';
import { create } from 'zustand';

interface UserState {
  loggedIn: boolean;
  user: User | null;
  token: string | undefined;
  userPreferences: UserPreferences | null;
  setLoggedIn: (loggedIn: boolean) => void;
  setUser: (user: User | null) => void;
  setToken: (token: string | undefined) => void;
  setUserPreferences: (preferences: UserPreferences) => void;
}

const useUserStore = create<UserState>((set) => ({
  loggedIn: false,
  user: null,
  token: undefined,
  userPreferences: null,
  setLoggedIn: (loggedIn) => {
    set({ loggedIn });
  },
  setUser: (user) => {
    set({ user });
  },
  setToken: (token) => {
    set({ token })
  },
  setUserPreferences: (preferences) => {
    set({ userPreferences: preferences });
  },
}));

export default useUserStore;