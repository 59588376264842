// ProductsHeader.tsx
import React, { useEffect, useState } from 'react';
import { Button } from '@/global-components/components/ui/button';
import Combobox from '@/global-components/components/combobox/Combobox';
import { Switch } from '@/global-components/components/ui/switch';
import { Tabs, TabsList, TabsTrigger } from '@/global-components/components/ui/tabs';
import { Code2, Grip, List, Torus } from 'lucide-react';
import { ProductsViewType } from '@/global-components/types';
import useUserStore from '@/context/useUserStore';
import { useMutation } from '@apollo/client';
import api from '@/global-components/api';

const ProductStatusFilterItems = [{ value: 'unapproved' }, { value: 'approved' }, { value: 'production' }];

interface ProductsHeaderProps {
  groupFilter: string;
  statusFilter: string;
  organisationFilter: string;
  groups: { groupTitle: string; groupId: string }[];
  organisations: { name: string; organisationId: string }[];
  groupByProjects: boolean;
  productsViewType: string;
  clearFilters: () => void;
  setStatusFilter: (value: string) => void;
  setGroupFilter: (value: string) => void;
  setOrganisationFilter: (value: string) => void;
  updateGroupByProjects: (value: boolean) => void;
  updateProductsViewType: (value: ProductsViewType) => void;
}

const ProductsHeader: React.FC<ProductsHeaderProps> = ({
  groupFilter,
  statusFilter,
  organisationFilter,
  groups,
  organisations,
  groupByProjects,
  productsViewType,
  clearFilters,
  setStatusFilter,
  setGroupFilter,
  setOrganisationFilter,
  updateGroupByProjects,
  updateProductsViewType,
}) => {
  const { userPreferences, setUserPreferences } = useUserStore()
  const [previewGcode, setPreviewGcode] = useState<boolean | undefined>(userPreferences?.previewGcode)
  const [updatePreferencesMutation, {loading: updatingPreferences}] = useMutation(api.user.mutations.UPDATE_USER_PREFERENCES)
  
  const handlePreviewFormatChange = (fileType: string) => {
    updatePreferencesMutation({ variables: {
      previewGcode: fileType === 'GCODE' ? true : false
    }})
      .then((result) => {
        if (result.data?.updateUserPreferences?.updatedUserPreferences) {
          setUserPreferences(result.data.updateUserPreferences.updatedUserPreferences)
          setPreviewGcode(result.data.updateUserPreferences.updatedUserPreferences.previewGcode)
        }
      })
  }

  useEffect(() => {
    setPreviewGcode(userPreferences?.previewGcode)
  }, [userPreferences?.previewGcode])
  return (
    <div className='flex justify-between mb-8 items-center pl-10'>
      <div className='flex gap-2 items-center'>
        <h1 className='text-2xl text-bw-green'>Your Products</h1>
      </div>
      <div className='flex items-center gap-12'>
        <div className='filter flex gap-2 items-center'>
          <div className='text-sm'>
            {groupFilter || statusFilter || organisationFilter ? (
              <Button variant='link' onClick={clearFilters} className='text-ui-warn-orange'>
                Clear Filter
              </Button>
            ) : (
              <div className='opacity-30'>Filter</div>
            )}
          </div>
          <Combobox
            title='Status'
            items={ProductStatusFilterItems}
            value={statusFilter}
            capitalise
            selectCallback={(newValue: string) => setStatusFilter(newValue)}
          />

          <div className='filter-by-project'>
            {groups?.length ? (
              <Combobox
                title='Project'
                wide
                items={groups?.map((group) => ({ name: group.groupTitle, value: group.groupId }))}
                value={groupFilter}
                selectCallback={(newValue: string) => setGroupFilter(newValue)}
              />
            ) : null}
          </div>

          <div className='filter-by-organisation'>
            {organisations?.length ? (
              <Combobox
                title='Organisation'
                wide
                items={organisations?.map((org) => ({ name: org.name, value: org.organisationId }))}
                value={organisationFilter}
                selectCallback={(newValue: string) => setOrganisationFilter(newValue)}
              />
            ) : null}
          </div>
        </div>
        <div className='view-settings flex justify-end items-center gap-6'>
          {/* <div className={`flex items-center gap-1 pointer-events-none opacity-30 ${productsViewType === 'LIST' && 'pointer-events-none opacity-30'}`}>
            <span className='text-xs text-bw-green font-medium'>Project View</span>
            <Switch checked={groupByProjects} onCheckedChange={updateGroupByProjects} />
          </div> */}
          <Tabs
            defaultValue='GRID'
            value={previewGcode ? 'GCODE':'STL'}
            className={`w-max ${updatingPreferences && 'opacity-30 pointer-events-none'}`}
            onValueChange={(value: string) => handlePreviewFormatChange(value)}
          >
            <TabsList className='bg-bw-grey'>
              <TabsTrigger value='STL'>
                <Torus className='h-3 w-3' />
              </TabsTrigger>
              <TabsTrigger value='GCODE'>
                <Code2 className='w-3 h-3' />
              </TabsTrigger>
            </TabsList>
          </Tabs>
          <Tabs
            defaultValue='GRID'
            value={productsViewType}
            className='w-max'
            onValueChange={(value: string) => updateProductsViewType(value as ProductsViewType)}
          >
            <TabsList className='bg-bw-grey'>
              <TabsTrigger value='GRID'>
                <Grip className='w-3 h-3' />
              </TabsTrigger>
              <TabsTrigger value='LIST'>
                <List className='w-3 h-3' />
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ProductsHeader;
