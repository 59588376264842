import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery, QueryResult } from '@apollo/client';
import { PrintRequest, User, Version, FilamentType, FileType, ProductType, ProductStatus, ProductPreferences, ProductGroup} from '@/global-components/types'

import api from '@/global-components/api'

import ProductDelete from './productDelete/ProductDelete';
import GroupSelect from './groupSelect/GroupSelect';
import VersionSelect from './versionSelect/VersionSelect';
import ProductDetails from './productDetails/ProductDetails';
import STLViewer from '../../components/STLViewer/STLViewer';

import ProductActions from '@/components/productRelated/ProductActions';
import GCodeViewer from '@/global-components/components/bw/gCodeViewer/GCodeViewer';
import { Pencil, Save, ChevronLeft, ChevronRight, ChevronDown, Info, XCircle, X, Check, Fullscreen, Minimize } from "lucide-react";

import helpers from "@/global-components/components/helpers"

import { useToast } from "@/global-components/components/ui/use-toast";

import './Product.scss';
import { Button } from '@/global-components/components/ui/button';
import OrganisationSelect from '@/global-components/components/bw/organisationSelect/OrganisationSelect';
import useUserStore from '@/context/useUserStore';
import usePreviewStore from '@/context/usePreviewStore';

const Product = (props: any) =>  {
  const { productRef } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const urlQuery = new URLSearchParams(location.search);
  const version = urlQuery.get('v');
  const { user, loggedIn } = useUserStore()
  const { setFileIdPreviewed } = usePreviewStore()

  const [files, setFiles] = useState<FileType[]>([]);
  const [previewableFiles, setPreviewableFiles] = useState<FileType[]>([])
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [gcode, setGcode] = useState<FileType | undefined>(undefined)

  const [forceReloadFiles, setForceReloadFiles] = useState<boolean>(false)

  const [viewerExpanded, expandViewer] = useState<boolean>(false)
  const [activePreview, setActivePreview] = useState<number>(0);
  const [product, setProduct] = useState<ProductType | null>(null);
  const [isSharedWithYou, setIsSharedWithYou] = useState<boolean>(false)

  const [uploading, setUploading] = useState(false);
  const [updatingOrgIds, setUpdatingOrgIds] = useState<string[]>([])

  const previewableFileExtenions = ['.stl', '.gcode']


  const productQuery: QueryResult = useQuery(api.products.queries.GET_PRODUCT_BY_REF, {
    variables: {reference: productRef, versionNumber: version},
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !productRef,
  });

  useEffect(() => {
    if (productQuery.error) {
      toast({
        title: "Loading Product Data",
        variant: 'destructive',
        description: "Error while fetching product data from Server. If this persists, please contact software@batch.works"
      })
    }
  }, [productQuery.error])

  const [updateProductWithFiles] = useMutation(api.products.mutations.UPDATE_PRODUCT_WITH_FILES);
  const [updateProduct, updateProductResult] = useMutation(api.products.mutations.UPDATE_PRODUCT);
  const [productStatusSeen] = useMutation(api.products.mutations.PRODUCT_STATUS_SEEN);
  const { toast } = useToast()

  const showNextFile = () => {
    if (!previewableFiles.length) {
      return
    }
    setActivePreview((prevActivePreview) => {
      const newIndex = prevActivePreview + 1 < previewableFiles.length ? prevActivePreview + 1 : 0
      setFileIdPreviewed(previewableFiles[newIndex].fileId)
      return newIndex
    })
  }
  
  const showPreviousFile = () => {
    setActivePreview((prevActivePreview) => {
      const newIndex = prevActivePreview - 1 >= 0 ? prevActivePreview - 1 : previewableFiles.length - 1
      setFileIdPreviewed(previewableFiles[newIndex].fileId)
      return newIndex
    })
  }
  

  const viewFileById = (id: string) => {
    setActivePreview(previewableFiles.findIndex(file => file.fileId === id))
    setFileIdPreviewed(id)
  }
  

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) return;
    const activeElement = document.activeElement;

    if (
      activeElement instanceof HTMLInputElement ||
      activeElement instanceof HTMLTextAreaElement ||
      activeElement instanceof HTMLSelectElement
    ) {
      return
    }

    switch (event.key) {
        case 'ArrowRight':
          showNextFile();
          break;
        case 'ArrowLeft': 
          showPreviousFile();
          break;
        case 'f':
          expandViewer(expanded => !expanded)
          break;
        default:
          break;
    }
  };

  const updateProductStatusSeen = () => {
    if (!product?.productId) {
      return;
    }
    productStatusSeen({ variables: { productId: product?.productId }})
      .then((result: any) => {
        
      })
      .catch((error : any) => { 
      })
  }

  const updateProductStatus = (status: ProductStatus) => {
    setUploading(true);
     updateProduct({ variables: { productId: product?.productId, status: status }})
      .then((result: any) => {
        if (result.data.updateProduct.success) {
          productQuery.refetch();
          setUploading(false);
          toast({
            title: "Product status successfully updated to " + status,
            variant: "success",
            duration: 2000
          })
        } else {
          setUploading(false);
        }
      })
      .catch((error : any) => { 
        setUploading(false);
        
      })
  };


  const updateProductGroup = (groupId: string) => {
    updateProduct({ variables: { productId: product?.productId, groupId: groupId}})
      .then((result: any) => {
        if (result.data.updateProduct.success) {
          productQuery.refetch();
          toast({
            title: "Product group successfully updated",
            variant: "success",
            duration: 2000
          })
        }
      })
      .catch((error : any) => {})
  }

  const toggleOrganisationOnProduct = (id: string) => {
    setUpdatingOrgIds([...updatingOrgIds, id])
    updateProduct({variables: {productId: product?.productId, organisationId: id }})
    .then((result: any) => {
      setUpdatingOrgIds(updatingOrgIds.filter((_id: string) => _id !== id))
      if (result.data.updateProduct.success) {
        productQuery.refetch();
        toast({
          title: "Changed product's organisations successfully",
          variant: 'success'
        })
      }
    })
    .catch((error : any) => {
      setUpdatingOrgIds(updatingOrgIds.filter((_id: string) => _id !== id))
      toast({
        title: "Error changing product's orgs",
        description: error,
        variant: 'destructive'
      })
    })
  }

  const addFilesToProduct = () => {
    setUploading(true);
    updateProductWithFiles({ variables: { files: filesToUpload, productId: product?.productId }})
      .then((result: any) => {
        setFilesToUpload([]);
        if (result.data.updateProductWithFiles.success) {
          setUploading(false);
          toast({
            title: `${filesToUpload.length} file${filesToUpload.length > 1 ? 's' : ''} added`,
            variant: 'success',
            duration: 3000
          })
          productQuery.refetch();
          filesQuery.refetch();
        } else {
          setUploading(false);
          // errors index and files index same index printable attribute will be false
          toast({
            title: "It Almost Worked",
            description: "Most likely the algorithm could not calculate the costs for the product. We have still added the GCode but not approved it, since we need to update the costs for it before it can be printed by the client.",
            duration: 10000,
          })
          productQuery.refetch();
        }
      })
      .catch((error : any) => {   
        setUploading(false);
        toast({
          title: "Error " + error.message,
          variant: 'destructive',
          duration: 3000
        })
      })  
  }

  const filesInProductChanged = (files: any[], previousFiles: any[]): boolean => {
    return forceReloadFiles ? true : !_.isEqual(
      files?.map((file: any) => file.fileId), 
      previousFiles?.map((file: any) => file.fileId)
    )
  }

  const refetchProduct = (reloadFiles: boolean) => {
    setForceReloadFiles(reloadFiles)
    productQuery.refetch()
    filesQuery.refetch()
  }

  useEffect(() => {
    if (product?.createdBy?.userId !== user?.userId) {
      setIsSharedWithYou(true)
    } else {
      setIsSharedWithYou(false)
    }
  }, [product, user])

  useEffect(() => {
    setProduct(productQuery?.data?.productByRef)
    if (filesInProductChanged(productQuery?.data?.productByRef.filemodelSet, productQuery?.previousData?.productByRef.filemodelSet)) {
      filesQuery.refetch()
    }
  }, [productQuery, product, uploading])

  const filesQuery = useQuery(api.products.queries.GET_FILES_FOR_PRODUCT, {
    variables: {
      productId: product?.productId
    },
    skip: !product?.productId
  })

  useEffect(() => {
    if (filesQuery.data?.filesForProduct) {
      const _files: FileType[] = [...(filesQuery.data?.filesForProduct ?? [])].sort((a, b) => {
        const aIsSTL = a.presignedUrl.toLowerCase().includes('.stl');
        const bIsSTL = b.presignedUrl.toLowerCase().includes('.stl');
        return aIsSTL === bIsSTL ? 0 : aIsSTL ? -1 : 1;
      })
      setFiles(_files)

      setPreviewableFiles(_files.filter(file => 
        previewableFileExtenions.some(ext => file.presignedUrl.toLowerCase().includes(ext))
      ));
      setGcode(_files.find(file => file.fileName.endsWith('.gcode')))
      setForceReloadFiles(false)
    }
  }, [filesQuery])

  useEffect(() => {
    if(product?.statusSeen === false) {
      updateProductStatusSeen();
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [product])

  if (!product) {
    return null
  }

  return (  
    <div className='view product'>
      <div className='product-container'>
        <div className={`product-model-preview-container ${viewerExpanded ? 'expanded' : ''}`}>
          <Button variant='minimal' className='absolute right-4 bottom-4 z-50' onClick={() => expandViewer(!viewerExpanded)}>
            {viewerExpanded ?
              <Minimize className='h-4 w-4'/>
              :
              <Fullscreen className='h-4 w-4'/>
            }
          </Button>
          <OrganisationSelect 
            productOrganisations={product.organisations ? product.organisations:[]}
            toggleOrganisation={toggleOrganisationOnProduct} 
            productLoading={productQuery.loading}
            access={user?.userId === product?.createdBy?.userId}
            updatingOrgIds={updatingOrgIds}
            buttonPositioningClasses='absolute left-28 top-[30px] z-10'/>
          <GroupSelect 
            productGroup={product?.groupId} 
            selectCallback={(groupId: string) =>  updateProductGroup(groupId)} 
            access={user?.userId === product?.createdBy?.userId} />
          {files && product?.productVersion?.length 
            ? <VersionSelect allowNewVersion={product.createdBy?.userId === user?.userId} productRef={productRef} currentVersionId={product?.productVersion[0].versionId} isLatestVersion={product.latestVersion ? true : false} existingFiles={files} selectCallback={() => { product.productVersion && navigate('/product/' + product?.productVersion[0]?.reference); productQuery.refetch(); }} /> 
            : null 
          }
          <div className={`viewer-vignette ${viewerExpanded ? 'hidden' : ''}`}></div>
          <div className='hint-container disappearing absolute w-full flex justify-center align-bottom bottom-14 z-10'>
            <div className='hint new-marker text-xs flex gap-1 items-center bg-bw-highlight/80 text-white'><Info className='h-3 w-3'/>Click anywhere on the model to add a new marker.</div>
          </div>
          <div className={files.length > 1 ? 'viewer-nav-container' : 'viewer-nav-container hidden'}>
            <div className='viewer-nav-item left h-16 w-16' onClick={showPreviousFile}>
              <ChevronLeft className='h-6 w-6' />
            </div>
            <div className='viewer-nav-item right h-16 w-16' onClick={showNextFile}>
              <ChevronRight className='h-6 w-6' />
            </div>
          </div>
          <div className='viewer-file-indicator-container'>            
            {product?.filemodelSet?.filter((filemodelSet: any) => 
              filemodelSet.presignedUrl.toLowerCase().includes('.stl') || filemodelSet.presignedUrl.toLowerCase().includes('.gcode'))
                ?.map((file: any, key: number) => 
                  <div onClick={() => setActivePreview(key)} key={key} className={key === activePreview 
                    ? 'viewer-file-indicator-item active' : 'viewer-file-indicator-item'}>
                      <div className='viewer-file-indicator-inner-circle'></div>
                  </div>
            )}
          </div>
          <div className='stl-viewer-container'>
            {previewableFiles.length === 0 &&
              <div className='flex items-center justify-center w-full text-sm text-bw-green/70'>
                This product has no 3d files to preview
              </div>
            }
            {previewableFiles.map((file: FileType, index: number) => 
              file.presignedUrl.toLowerCase().includes('.stl') 
                ? <div className='viewer stl flex items-center justify-center w-full h-full' key={index} style={{transform: `translateX(${-activePreview * 100}%)`}}>
                    <STLViewer file={file} editable={product.createdBy?.userId === user?.userId}/>
                  </div> 
                : <div className='viewer gcode flex items-center justify-center w-full h-full' key={index} style={{transform: `translateX(${-activePreview * 100}%)`}}>
                  {file.fileattributesmodelSet?.length && 
                    <GCodeViewer gcodeFile={file} nozzleDiameter={file.fileattributesmodelSet[0]?.nozzleSize} />
                  }
                </div> 
            )}            
          </div>
        </div>
        <ProductDetails 
          product={product}
          isSharedWithYou={isSharedWithYou}
          filesQueryLoading={filesQuery.loading}
          files={files}
          classNames={viewerExpanded ? 'hidden' : ''}
          filesToUpload={filesToUpload}
          setFilesToUpload={setFilesToUpload}
          updateWithNewFiles={() => addFilesToProduct()}
          uploading={uploading}
          refetchProduct={(reloadFiles: boolean) => refetchProduct(reloadFiles)}
          viewFileById={(id: string) => viewFileById(id)}
        />
        
        <ProductActions userIsSuperuser={user?.isSuperuser ? true : false} 
          productStatus={product?.status as ProductStatus} 
          files={files}
          filesToUpload={filesToUpload}
          updateWithNewFiles={() => addFilesToProduct()}
          uploading={uploading} 
          classNames={viewerExpanded ? 'hidden' : ''}
          updateProductStatus={(toStatus: ProductStatus) => updateProductStatus(toStatus)}
        />
      </div>
    </div>
  );
}

export default Product;
