import React from "react";
import { Button } from "./button";
import { Loader } from "lucide-react";

interface PaginationControlsProps {
  page: number;
  totalPages: number;
  onChange: (page: number) => void;
  loading?: boolean;
  className?: string;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({
  page,
  totalPages,
  onChange,
  loading=false,
  className = "",
}) => {
  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onChange(newPage);
    }
  };

  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      {/* Previous Button */}
      <Button
        variant="outline"
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
      >
        Previous
      </Button>

      {/* Page Buttons */}
      {Array.from({ length: totalPages }, (_, index) => {
        const pageNumber = index + 1
        const isActivePage = page === pageNumber
        return (
          <Button
            key={pageNumber}
            variant={isActivePage ? "bwprimary" : "outline"}
            onClick={() => handlePageChange(pageNumber)}
          >
            {(isActivePage && loading) ?
              <Loader className="h-4 w-4 animate-spin" />
              :pageNumber
            }
          </Button>
        );
      })}

      {/* Next Button */}
      <Button
        variant="outline"
        onClick={() => handlePageChange(page + 1)}
        disabled={page === totalPages}
      >
        Next
      </Button>
    </div>
  );
};

export default PaginationControls;