// src/components/ProductParts.tsx

import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button } from '@/global-components/components/ui/button';
import { Input } from '@/global-components/components/ui/input';
import { Checkbox } from '@/global-components/components/ui/checkbox';
import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import { ChevronRight, ChevronDown, Plus, Loader, XCircle, Check, X, Boxes, Link, Trash2, MinusCircle, Unlink2, Link2, AlertTriangle, Barcode, ScanBarcode } from 'lucide-react';
import { Separator } from '@radix-ui/react-select';
import _ from 'lodash';

import api from '@/global-components/api'
import helpers from '@/global-components/components/helpers';
import { PartType, FileType, ProductType, User, ProductPartLinkType } from '@/global-components/types';
import { useToast } from '@/global-components/components/ui/use-toast';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/global-components/components/ui/collapsible';
import GcodeFileInfo from '@/components/productRelated/GcodeFileInfo';
import FileInfo from '@/components/productRelated/FileInfo';
import Dropdown from '@/global-components/components/dropdown/Dropdown';
import Combobox from '@/global-components/components/combobox/Combobox';
import FileTag from '@/global-components/components/bw/FileTag';
import useUserStore from '@/context/useUserStore';
import ImageOverlay from '@/global-components/components/bw/ImageOverlay';

type ProductPartsProps = {
  product: ProductType;
  parts: PartType[];
  linkedParts: ProductPartLinkType[];
  files: FileType[];
  unassignedFiles: FileType[];
  latestVersion: boolean;
  viewFileById?: Function;
  refetchParts: () => void;
};

const ProductParts: React.FC<ProductPartsProps> = ({
  product,
  parts,
  linkedParts,
  files,
  unassignedFiles,
  latestVersion,
  viewFileById,
  refetchParts,
}) => {
  const { toast } = useToast()
  const { user } = useUserStore()

  // State variables
  const [createNewPartFormVisible, setCreateNewPartFormVisible] = useState<boolean>(false);
  const [newPartFiles, setNewPartFiles] = useState<FileType[]>([]);
  const [newPartName, setNewPartName] = useState<string | undefined>(undefined);
  const [newPartSku, setNewPartSku] = useState<string | undefined>(undefined);
  const [partsBeingDeleted, setPartsBeingDeleted] = useState<string[]>([]);
  const [productPartLinksBeingDeleted, setProductPartLinksBeingDeleted] = useState<string[]>([]);
  const [partsBeingUpdated, setPartsBeingUpdated] = useState<string[]>([]);
  const [filesBeingRemoved, setFilesBeingRemoved] = useState<string[]>([]);
  const [partAddFilePopoverOpen, setPartAddFilePopoverOpen] = useState<string | undefined>(undefined);
  const [filesToAddToPart, setFilesToAddToPart] = useState<FileType[]>([]);
  const [partNameBuffer, setPartNameBuffer] = useState<string | undefined>(undefined);
  const [partNameFocused, setPartNameFocused] = useState<string | undefined>(undefined);
  const [partSkuBuffer, setPartSkuBuffer] = useState<string | undefined>(undefined);
  const [partSkuFocused, setPartSkuFocused] = useState<string | undefined>(undefined);
  const [fetchUserParts, setFetchUserParts] = useState<boolean>(false)
  const [imageOpen, setImageOpen] = useState<string | null>(null);

  // Queries
	const userPartsQuery = useQuery(api.products.queries.GET_PARTS_OF_USER, {
		variables: {
			userId: user?.userId
		},
    skip: !fetchUserParts
	})

  // Mutations
  const [createPartMutation, { loading: creatingPart }] = useMutation(api.products.mutations.CREATE_PART_FOR_PRODUCT);
  const [deletePartMutation, { loading: deletingPart }] = useMutation(api.products.mutations.DELETE_PART);
  const [updatePartMutation, { loading: updatingPart }] = useMutation(api.products.mutations.UPDATE_PART);
  const [createProductPartLinkMutation, { loading: linkingPart }] = useMutation(api.products.mutations.CREATE_PRODUCT_PART_LINK)
  const [deleteProductPartLinkMutation, { loading: unlinkingPart }] = useMutation(api.products.mutations.REMOVE_PRODUCT_PART_LINK)

  // Functions
  const createPart = () => {
    const fileIds: string[] = newPartFiles.map((file) => file.fileId);
    createPartMutation({
      variables: { productId: product.productId, fileIds: fileIds, partName: newPartName, sku: newPartSku },
    })
      .then((result) => {
        setNewPartFiles([]);
        setNewPartName(undefined);
        setNewPartSku(undefined)
        setCreateNewPartFormVisible(false);
        toast({
          title: 'Part Created',
          variant: 'success',
          duration: 3000,
        });
        refetchParts();
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: 'Part Creation Failed',
          variant: 'destructive',
          duration: 3000,
        });
      });
  };

  const onPartNameBlur = (part: PartType, newName: string) => {
    if (partNameBuffer === part.partName) {
      setPartNameBuffer(undefined);
      setPartNameFocused(undefined);
      return;
    }
    setPartsBeingUpdated([...partsBeingUpdated, part.partId]);
    updatePartMutation({ variables: { partId: part.partId, partName: newName } })
      .then((result) => {
        refetchParts();
        setPartNameBuffer(undefined);
        setPartNameFocused(undefined);
        toast({
          title: 'Updated part name',
          variant: 'success',
          duration: 3000,
        });
        setPartsBeingUpdated(partsBeingUpdated.filter((id) => id !== part.partId));
      })
      .catch((error) => {
        setPartNameBuffer(undefined);
        setPartNameFocused(undefined);
        toast({
          title: 'Updating part failed',
          variant: 'destructive',
          duration: 3000,
        });
        setPartsBeingUpdated(partsBeingUpdated.filter((id) => id !== part.partId));
      });
  };

  const onPartSkuBlur = (part: PartType, newSku: string) => {
    if (partSkuBuffer === part.sku) {
      setPartSkuBuffer(undefined);
      setPartSkuFocused(undefined);
      return;
    }
    setPartsBeingUpdated([...partsBeingUpdated, part.partId]);
    updatePartMutation({ variables: { partId: part.partId, sku: newSku } })
      .then((result) => {
        refetchParts();
        setPartSkuBuffer(undefined);
        setPartSkuFocused(undefined);
        toast({
          title: 'Updated part sku',
          variant: 'success',
          duration: 3000,
        });
        setPartsBeingUpdated(partsBeingUpdated.filter((id) => id !== part.partId));
      })
      .catch((error) => {
        setPartSkuBuffer(undefined);
        setPartSkuFocused(undefined);
        toast({
          title: 'Updating part failed',
          variant: 'destructive',
          duration: 3000,
        });
        setPartsBeingUpdated(partsBeingUpdated.filter((id) => id !== part.partId));
      });
  };

  const addFilesToPart = (partId: string) => {
    setPartsBeingUpdated([...partsBeingUpdated, partId]);
    const fileIds: string[] = filesToAddToPart.map((file) => file.fileId);
    updatePartMutation({ variables: { partId: partId, addFileIds: fileIds } })
      .then((result) => {
        refetchParts();
        toast({
          title: 'Files added to part',
          variant: 'success',
          duration: 3000,
        });
        setPartsBeingUpdated(partsBeingUpdated.filter((id) => id !== partId));
      })
      .catch((error) => {
        toast({
          title: 'Updating part failed',
          variant: 'destructive',
          duration: 3000,
        });
        console.log(error);
        setPartsBeingUpdated(partsBeingUpdated.filter((id) => id !== partId));
      })
      .finally(() => {
        setFilesToAddToPart([])
      })
  };

  const removeFileFromPart = (partId: string, fileId: string) => {
    setFilesBeingRemoved([...filesBeingRemoved, fileId]);
    updatePartMutation({ variables: { partId: partId, removeFileIds: [fileId] } })
      .then((result) => {
        refetchParts();
        setFilesBeingRemoved(filesBeingRemoved.filter((id) => id !== fileId));
        toast({
          title: 'Files have been removed from part',
          variant: 'success',
          duration: 3000,
        });
      })
      .catch((error) => {
        toast({
          title: 'Removing files failed',
          variant: 'destructive',
          duration: 5000,
        });
        console.log(error);
        setFilesBeingRemoved(filesBeingRemoved.filter((id) => id !== fileId));
      });
  };

  const deletePart = (id: string) => {
    setPartsBeingDeleted([...partsBeingDeleted, id]);
    deletePartMutation({ variables: { partId: id } })
      .then((result) => {
        refetchParts();
        toast({
          title: 'Part Deleted',
          variant: 'success',
          duration: 3000,
        });
      })
      .catch((error) => {
        toast({
          title: 'Deleting part failed',
          variant: 'destructive',
          duration: 3000,
        });
        console.log(error);
      })
      .finally(() => {
        setPartsBeingDeleted(partsBeingDeleted.filter((partId) => partId !== id));
      });
  };

  const cancelNewPart = () => {
    setNewPartName(undefined);
    setNewPartSku(undefined)
    setNewPartFiles([]);
    setCreateNewPartFormVisible(false);
  };

  const createProductPartLink = (partId: string) => {
		createProductPartLinkMutation({ variables: { productId: product.productId, partId: partId }})
			.then((result) => {
				if (result.data.createProductPartLink.success) {
					toast({
						title: "Part linked successfully",
						variant: 'success',
						duration: 3000,
					});
          refetchParts()
				} else {
					toast({
						title: "Lik failed",
						variant: 'destructive',
						description: 'Something went wrong',
						duration: 5000
					});
				}
			})
	}

  const deleteProductPartLink = (productPartLinkId: string) => {
    setProductPartLinksBeingDeleted([...productPartLinksBeingDeleted, productPartLinkId])
    deleteProductPartLinkMutation({ variables: {id: productPartLinkId}})
      .then((result) => {
        refetchParts();
        toast({
          title: 'Part unlinked',
          variant: 'success',
          duration: 3000,
        });
      })
      .catch((error) => {
        toast({
          title: 'Unlinking part failed',
          variant: 'destructive',
          duration: 3000,
        });
        console.log(error);
      })
      .finally(() => {
        setProductPartLinksBeingDeleted(productPartLinksBeingDeleted.filter((id) => id !== productPartLinkId));
      });
  }

  const getFilteredItems = (userParts: PartType[] | undefined): any[] => {
    if (!userParts) return []; // Ensure an empty array is returned if there's no data
  
    return userParts
      .filter((p: PartType) => !linkedParts.some(lp => lp.part.partId === p.partId))
      .filter((p: PartType) => !parts.some(pp => pp.partId === p.partId))
      .map((part: PartType) => ({
        name: part.partName,
        value: part.partId,
        subtitle: part.parentProductName
      }));
  };
  

  return (
    <div className="parts flex flex-col gap-2 text-sm">
      <ImageOverlay url={imageOpen} callback={() => setImageOpen(null)} />
      {parts.map((part: PartType, index: number) => (
        <div
          key={part.partId}
          className="part flex flex-col gap-2 p-2 border border-bw-green/10 bg-white/50 rounded-xl group/part hover:border-bw-green/20"
        >
          <div className="flex items-center justify-between gap-2 font-medium">
            <Input
              className={`border-transparent bg-transparent w-2/5 hover:border-bw-green/10 font-bold focus:border ${
                partsBeingUpdated.includes(part.partId) && 'opacity-30 pointer-events-none'
              } ${!latestVersion && 'opacity-100 pointer-events-none'}`}
              value={
                partNameBuffer !== undefined && partNameFocused === part.partId
                  ? partNameBuffer
                  : part.partName
              }
              onChange={(e) => setPartNameBuffer(e.target.value)}
              onFocus={() => {
                setPartNameFocused(part.partId);
                setPartNameBuffer(part.partName);
              }}
              onBlur={(e) => onPartNameBlur(part, e.target.value)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  e.currentTarget.blur();
                }
              }}
            />
            <div className='relative w-full flex items-center'>
              <ScanBarcode className='h-4 w-4 relative right-0' />
              <Input
                className={`border-transparent bg-transparent pl-2 hover:border-bw-green/10 focus:border ${
                  partsBeingUpdated.includes(part.partId) && 'opacity-30 pointer-events-none'
                } ${!latestVersion && 'opacity-100 pointer-events-none'}`}
                value={
                  partSkuBuffer !== undefined && partSkuFocused === part.partId
                    ? partSkuBuffer
                    : part.sku
                }
                onChange={(e) => setPartSkuBuffer(e.target.value)}
                onFocus={() => {
                  setPartSkuFocused(part.partId);
                  setPartSkuBuffer(part.sku);
                }}
                onBlur={(e) => onPartSkuBlur(part, e.target.value)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    e.currentTarget.blur();
                  }
                }}
              />
            </div>

            {partsBeingDeleted.includes(part.partId) ? (
              <Button
                variant="destructiveminimal"
                disabled
                className="text-ui-denial-red flex gap-1 items-center whitespace-nowrap"
                onClick={() => deletePart(part.partId)}
              >
                <Loader className="h-4 w-4 animate-spin" /> Deleting
              </Button>
            ) : (
              <Button
                variant="destructiveoutline"
                className={`text-ui-denial-red flex gap-1 items-center whitespace-nowrap opacity-0 group-hover/part:opacity-100 ${
                  !latestVersion && 'opacity-0 group-hover/part:opacity-0 pointer-events-none'
                }`}
                onClick={() => deletePart(part.partId)}
              >
                <Trash2 className='h-4 w-4' />
                Delete part
              </Button>
            )}

            <Popover
              open={partAddFilePopoverOpen === part.partId}
              onOpenChange={() => {
                if (partAddFilePopoverOpen === undefined) {
                  setPartAddFilePopoverOpen(part.partId);
                } else {
                  setPartAddFilePopoverOpen(undefined);
                  setFilesToAddToPart([]);
                }
              }}
            >
              <PopoverTrigger asChild>
                <Button
                  variant="confirmoutline"
                  title="Add file to part"
                  size="icon"
                  disabled={partsBeingUpdated.includes(part.partId)}
                  className={`flex items-center ${
                    partAddFilePopoverOpen === part.partId ? 'opacity-100' : 'opacity-0'
                  } group-hover/part:opacity-100 ${
                    partsBeingUpdated.includes(part.partId) ? 'group-hover/part:opacity-30' : ''
                  } ${!latestVersion && 'opacity-0 group-hover/part:opacity-0 pointer-events-none'}`}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="max-w-96 bg-white p-6 rounded-xl shadow-2xl z-30" align="end">
                <div className="flex flex-col gap-3">
                  <div className="font-bold text-sm">Selected files will be added to part</div>
                  {unassignedFiles.map((unassignedFile: FileType) => (
                    <div key={unassignedFile.fileId} className="flex items-center gap-2 font-mono font-normal">
                      <Checkbox
                        id={unassignedFile.fileId}
                        checked={
                          filesToAddToPart.some((file) => unassignedFile.fileId === file.fileId)
                            ? true
                            : false
                        }
                        onCheckedChange={(checked: boolean) => {
                          if (checked) {
                            setFilesToAddToPart([...filesToAddToPart, unassignedFile]);
                          } else {
                            setFilesToAddToPart(
                              filesToAddToPart.filter((file) => file.fileId !== unassignedFile.fileId)
                            );
                          }
                        }}
                      />
                      <label
                        className="flex items-center gap-1 justify-between"
                        htmlFor={unassignedFile.fileId}
                      >
                        {/* Assuming FileTag is a component that displays file type */}
                        <FileTag type={helpers.extractFileTypeFromFilename(unassignedFile.fileName) as any} />
                        {unassignedFile.fileName}
                      </label>
                    </div>
                  ))}

                  <div className="flex gap-1 mt-2">
                    <Button
                      variant="destructiveoutline"
                      onClick={() => {
                        setFilesToAddToPart([]);
                        setPartAddFilePopoverOpen(undefined);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="bwconfirm"
                      className="grow"
                      disabled={filesToAddToPart.length === 0}
                      onClick={() => {
                        addFilesToPart(part.partId);
                        setPartAddFilePopoverOpen(undefined);
                      }}
                    >
                      Add files
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>

          {/* Display Gcode Files */}
          {part.files.filter(helpers.filters.isFileGcode)?.map((file: FileType, index: number) => (
            <div key={file.fileId} className="flex flex-col gap-1">
              <GcodeFileInfo
                product={product}
                updating={filesBeingRemoved.includes(file.fileId)}
                file={file}
                key={index}
                index={index}
                showActions={true}
                user={product.createdBy as User}
                showDetails
                inPart
                removeFileFromPart={(fileId: string) => removeFileFromPart(part.partId, fileId)}
                viewOnly={!latestVersion}
                view={(id: string) => viewFileById && viewFileById(id)}
              />
            </div>
          ))}

          {/* Display STL Files */}
          {part.files.filter(helpers.filters.isFileStl)?.map((file: FileType, index: number) => (
            <FileInfo
              key={file.fileId}
              file={file}
              inPart
              updating={filesBeingRemoved.includes(file.fileId)}
              product={product}
              removeFileFromPart={(fileId: string) => removeFileFromPart(part.partId, fileId)}
              viewOnly={!latestVersion}
              view={(id: string) => viewFileById && viewFileById(id)}
            />
          ))}

          {/* Display Image Files */}
          {part.files.filter(helpers.filters.isFileImage)?.map((file: FileType, index: number) => (
            <FileInfo
              key={file.fileId}
              file={file}
              inPart
              previewImage
              updating={filesBeingRemoved.includes(file.fileId)}
              product={product}
              removeFileFromPart={(fileId: string) => removeFileFromPart(part.partId, fileId)}
              viewOnly={!latestVersion}
              view={(id: string) => setImageOpen(file.presignedUrl)}
            />
          ))}

          {/* Display Other Files */}
          {part.files.filter(helpers.filters.isOtherFile)?.map((file: FileType, index: number) => (
            <FileInfo
              key={file.fileId}
              file={file}
              inPart
              product={product}
              callback={(id: string) => files.filter((file) => file.fileId !== id)}
              removeFileFromPart={(fileId: string) => removeFileFromPart(part.partId, fileId)}
              viewOnly={!latestVersion}
              view={(id: string) => viewFileById && viewFileById(id)}
            />
          ))}
        </div>
      ))}
      {/* <div className={`text-xs font-bold text-bw-green/70 w-full pl-5 text-center capitalize mt-3 ${linkedParts.length === 0 && 'hidden'}`}>
        LINKED FROM OTHER PRODUCT
      </div> */}
      {linkedParts.map((link: ProductPartLinkType) => {
        return (
          <div
            key={link.part.partId}
            className={`part flex flex-col gap-2 p-2 border  border-bw-green/10 bg-white/50 rounded-xl group/part hover:border-bw-green/20
              ${productPartLinksBeingDeleted.find((id) => id === link.id) ? 'opacity-30 pointer-events-none' : ''}`}
          >
            <div className='font-medium text-bw-green pl-3 flex items-center justify-between gap-1'>
              <div className='flex items-center gap-2'>
                {link.part.partName}
                <div className='flex relative top-[1px] items-center gap-0.5'>
                  <Link2 className='h-3 w-3 opacity-50' />
                  <span className='text-xs text-bw-green opacity-50'>{link.part.parentProductName}</span>
                </div>

              </div>
              <div className='flex items-center gap-1'>                
                <Button 
                variant='destructiveoutline' 
                className='flex gap-1 items-center opacity-0 group-hover/part:opacity-100'
                onClick={() => deleteProductPartLink(link.id)}>
                  {productPartLinksBeingDeleted.find((id) => id === link.id) ?
                    <Loader className='h-4 w-4 animate-spin' />
                    :
                    <div className='flex items-center gap-1'>
                      <Unlink2 className='h-4 w-4' />
                      Unlink
                    </div>                    
                  }
                </Button>
              </div>
            </div>
            {/* Display Gcode Files */}
            {link.part.files.filter(helpers.filters.isFileGcode)?.map((file: FileType, index: number) => (
              <div key={file.fileId} className="flex flex-col gap-1">
                <GcodeFileInfo
                  product={product}
                  updating={filesBeingRemoved.includes(file.fileId)}
                  file={file}
                  key={index}
                  index={index}
                  showActions={true}
                  user={product.createdBy as User}
                  showDetails
                  inPart
                  noEdit
                  removeFileFromPart={(fileId: string) => removeFileFromPart(link.part.partId, fileId)}
                  viewOnly={!latestVersion}
                  view={(id: string) => viewFileById && viewFileById(id)}
                />
              </div>
            ))}

            {/* Display STL Files */}
            {link.part.files.filter(helpers.filters.isFileStl)?.map((file: FileType, index: number) => (
              <FileInfo
                key={file.fileId}
                file={file}
                inPart
                noEdit
                updating={filesBeingRemoved.includes(file.fileId)}
                product={product}
                removeFileFromPart={(fileId: string) => removeFileFromPart(link.part.partId, fileId)}
                viewOnly={!latestVersion}
                view={(id: string) => viewFileById && viewFileById(id)}
              />
            ))}

            {/* Display Image Files */}
            {link.part.files.filter(helpers.filters.isFileImage)?.map((file: FileType, index: number) => (
              <FileInfo
                key={file.fileId}
                file={file}
                inPart
                noEdit
                previewImage
                updating={filesBeingRemoved.includes(file.fileId)}
                product={product}
                removeFileFromPart={(fileId: string) => removeFileFromPart(link.part.partId, fileId)}
                viewOnly={!latestVersion}
                view={(id: string) => viewFileById && viewFileById(id)}
              />
            ))}

            {/* Display Other Files */}
            {link.part.files.filter(helpers.filters.isOtherFile)?.map((file: FileType, index: number) => (
              <FileInfo
                key={file.fileId}
                file={file}
                noEdit
                product={product}
                callback={(id: string) => files.filter((file) => file.fileId !== id)}
                viewOnly={!latestVersion}
                view={(id: string) => viewFileById && viewFileById(id)}
              />
            ))}
          </div>
        )
      })}

      {/* New Part Form */}
      {createNewPartFormVisible ? (
        <div className={`flex flex-col gap-1 ${parts.length && 'mt-2'}`}>
          <div className="border border-bw-grey rounded-xl bg-white/70 p-4 text-sm flex flex-col gap-4">
            <div className='flex flex-col gap-1'>
              <Input
                type="text"
                name="partName"
                placeholder="Name your new part (this can be changed later)"
                value={newPartName}
                onChange={(e) => setNewPartName(e.target.value)}
              />
              <Input
                type="text"
                name="partSku"
                placeholder="Add an SKU for your part"
                value={newPartSku}
                onChange={(e) => setNewPartSku(e.target.value)}
              />
            </div>
            <div className="pl-3 flex flex-col gap-3 mb-2">
              <div className="font-bold text-sm">Selected files will be added to part</div>
              {!unassignedFiles.length &&
                <div className='opacity-30 flex items-center gap-1'>
                  <AlertTriangle className='h-4 w-4' />
                  All files already assigned to other parts.
                </div>
              }
              {unassignedFiles.map((unassignedFile: FileType) => (
                <div key={unassignedFile.fileId} className="flex items-center gap-2 font-mono">
                  <Checkbox
                    id={unassignedFile.fileId}
                    checked={
                      newPartFiles.some((file) => unassignedFile.fileId === file.fileId) ? true : false
                    }
                    onCheckedChange={(checked: boolean) => {
                      if (checked) {
                        setNewPartFiles([...newPartFiles, unassignedFile]);
                      } else {
                        setNewPartFiles(newPartFiles.filter((file) => file.fileId !== unassignedFile.fileId));
                      }
                    }}
                  />
                  <label className="flex items-center gap-1 justify-between" htmlFor={unassignedFile.fileId}>
                    <FileTag type={helpers.extractFileTypeFromFilename(unassignedFile.fileName) as any} />
                    {unassignedFile.fileName}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex gap-1 items-center">
            <Button variant="bwsecondary" disabled={creatingPart} onClick={() => cancelNewPart()}>
              Cancel
            </Button>
            <Button
              variant="bwconfirm"
              disabled={!newPartName || !newPartFiles.length || creatingPart}
              onClick={() => createPart()}
            >
              {creatingPart ? <Loader className="h-4 w-4 animate-spin" /> : <div>Save</div>}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex gap-1 items-center">
          <Button
            variant="bwprimary"
            onClick={() => setCreateNewPartFormVisible(true)}
            className={`w-min ${!latestVersion && 'hidden'} whitespace-nowrap ${(parts.length + linkedParts.length) && 'mt-0'}`}
          >
            <Plus className="h-4 w-4" /> Create New Part
          </Button>
          <Combobox
            title="Link existing part"
            titleIcon={<Link2 className='h-4 w-4' />}
            trigger
            onOpenChange={(open: boolean) => setFetchUserParts(open)}
            items={getFilteredItems(userPartsQuery.data?.partsOfUser)}
            loading={userPartsQuery.loading}
            customPlaceholder='Search across your products'
            value={null}
            wide
            itemFlexCol
            buttonClassName={`w-min ${!latestVersion && 'hidden'} whitespace-nowrap ${(parts.length + linkedParts.length) && 'mt-0'}`}
            selectCallback={(partId: string) => createProductPartLink(partId)}
          />
        </div>
      )}
    </div>
  );
};

export default ProductParts;
