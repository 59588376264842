import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import * as GCodePreview from 'gcode-preview'
import { CirclePicker, ColorResult } from 'react-color';
import { Canvas, ThreeEvent, useFrame, useThree, useLoader, extend } from '@react-three/fiber';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast } from 'three-mesh-bvh';
import { Circle, AccumulativeShadows, Line, Extrude, Environment, OrbitControls, Grid, Plane, CameraControls, SoftShadows, BakeShadows, RandomizedLight, ContactShadows, Sphere, Detailed, PerspectiveCamera } from '@react-three/drei';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { Model, Layer, ExtruderPoint, FileType } from '@/global-components/types'
import { parseGcode } from '../GCodeParser';
import { Switch } from "@/global-components/components/ui/switch"
import { Input } from '@/global-components/components/ui/input'
import { CustomLinearPath } from './CustomLinearPath';
import SetOrbit from './SetOrbit'
import Floor from './Floor'
import { customPhongShader } from './Shaders';
import { createChamferedRectShape, createRoundedRectShape, vectorsFromPoints, modelToThreeLines, calculateExtrusionWidth, 
  getLayerHeight, getAverageExtrusionWidthForVaseLayer, calculateOffsetPoint } from './helpers';
import ProgressBar from '../progressBar/ProgressBar';
import { Button } from '../../ui/button';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/global-components/components/ui/popover"
import { Spin } from 'react-cssfx-loading';
import { Loader } from 'lucide-react';

THREE.Mesh.prototype.raycast = acceleratedRaycast;
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;

type GCodeViewerProps = {
  gcodeFile: FileType | undefined;
  nozzleDiameter: number | undefined;
  miniPreview?:boolean;
}

const floorSize: number = 300
const segmentResolutionThreshold: number = 130000;

const GCodeViewer: React.FC<GCodeViewerProps> = ({gcodeFile, nozzleDiameter, miniPreview=false}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [lines, setLines] = useState<THREE.Vector3[][]>([])
  const [hoveredLine, setHoveredLine] = useState<number>(-1)
  const [activeLayer, setActiveLayer] = useState<number>(-1)
  const [highlightedGCode, setHighlightedGCode] = useState<string>('')
  const [showModel, setShowModel] = useState<boolean>(false)
  const [showGrid, setShowGrid] = useState<boolean>(true)
  const [filamentDiameter, setFilamentDiameter] = useState<number>(1.75)
  const [shadowsEnabled, setShadowsEnabled] = useState<boolean>(true)
  const lineModel = useRef<any>(null)
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false)

  const cameraControls = useRef<CameraControls>(null)
  const camera = useRef<THREE.PerspectiveCamera>(null)
  const orbitControlsRef = useRef<OrbitControlsImpl | null>(null)
  const [cameraOrbit, setCameraOrbit] = useState<THREE.Vector3>(new THREE.Vector3(0, 0, 0))
  const [cameraPos, setCameraPos] = useState<THREE.Vector3>(new THREE.Vector3(0, 0, 0))
  const [cameraBeenCentered, setCameraBeenCentered] = useState<boolean>(false)
  
  const [mesh, setMesh] = useState<any>(null)

  const meshRef: any = useRef<any>(null)  

  const [model, setModel] = useState<Model | null>(null);
  const [geometry, setGeometry] = useState<THREE.BufferGeometry | undefined>(undefined);
  const [progressGeometry, setProgressGeometry] = useState<number>(0)
  const [shaderMaterial, setShaderMaterial] = useState<THREE.ShaderMaterial>(
    customPhongShader({r: 200, g: 200, b: 200}, 
      {r: 200, g: 200, b: 200}, 
      {r: 200, g: 200, b: 200},
      new THREE.Vector3(500, 2000, 200),
      new THREE.Vector3(500, 2000, 200),
      20)
  )

  const [gcodeFilamentMatt, setGcodeFilamentMatt] = useState<boolean>(false)
  const [gcodeFilamentColor, setGcodeFilamentColor] = useState<string>('#FFFEF3')

  
  const filamentColourChanged = (color: ColorResult) => {
    setGcodeFilamentColor(color.hex)
  }

  const [rawGcode, setRawGcode] = useState<string | undefined>(undefined)
  useEffect(() => {
    if (!gcodeFile) return
    fetch(gcodeFile.presignedUrl)
      .then(response => response.text())
      .then((response) => {
        const text: string = response;
        if (typeof text === 'string') {
          setRawGcode(text)
          loadGcode(text);
        }
      })
  }, [gcodeFile])

  const gcodeShortcuts = (e: KeyboardEvent) => {
    const activeElement = document.activeElement;

    if (
      activeElement instanceof HTMLInputElement ||
      activeElement instanceof HTMLTextAreaElement ||
      activeElement instanceof HTMLSelectElement
    ) {
      return
    }
    
    if (e.key == 'p' || e.key == 'P') {
      setShowModel(showModel => !showModel)
    } else if (e.key == 'g' || e.key == 'G') {
      setShowGrid(showGrid => !showGrid)
    } else if (e.key == 'c' || e.key == 'C') {
      centerCamera()
    }
  }

  const centerCamera = () => {
    if (model && camera.current) {
      // Calculate bounding box of the model
      const boundingBox = new THREE.Box3(
        new THREE.Vector3(
          model.modelBounds.x.min,
          model.modelBounds.y.min,
          model.modelBounds.z.min
        ),
        new THREE.Vector3(
          model.modelBounds.x.max,
          model.modelBounds.y.max,
          model.modelBounds.z.max
        )
      );
  
      // Get the center and size of the bounding box
      const center = boundingBox.getCenter(new THREE.Vector3());
      const size = boundingBox.getSize(new THREE.Vector3());
  
      // Set the target for OrbitControls
      setCameraOrbit(center);
  
      // Compute the distance the camera needs to be from the model to fit it in the view
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = (camera.current.fov * Math.PI) / 180; // Convert FOV to radians
      let cameraDistance = maxDim / (2 * Math.tan(fov / 2));
  
      // Optional: Add some padding to the camera distance
      cameraDistance *= 1;
  
      // Update the camera position
      camera.current.position.set(
        center.x,
        center.y - cameraDistance,
        center.z + cameraDistance
      );
      camera.current.lookAt(center);
  
      // Update the OrbitControls target
      if (orbitControlsRef.current) {
        orbitControlsRef.current.target.copy(center);
        orbitControlsRef.current.update();
      }
    }
  }
  

  const loadGcode = (gcode: string) => {
    const _model: Model | null = parseGcode(gcode, nozzleDiameter, filamentDiameter);
    setModel(_model);
    if(cameraControls.current && _model && !cameraBeenCentered) {
      setCameraBeenCentered(true)
      // centerCamera()
    }
    if (_model !== null) {
      setLines(lines)
    }  
  }

  useEffect(() => {
    if(camera.current && model) {
      centerCamera()
      if (miniPreview) {
        return
      } else {
        window.addEventListener('keydown', gcodeShortcuts)
        return () => {
          window.removeEventListener('keydown', gcodeShortcuts)
        }
      }
    }
  }, [camera?.current, model])

  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });


  const handleLineClick = (layerIndex: number) => {
    setActiveLayer(layerIndex);
    document.getElementById('gcode-layer-' + layerIndex)?.scrollIntoView({behavior: 'smooth'})
    const selectionGcode: string | undefined = model?.layers[layerIndex].extruderPoints.reduce((acc: string, extruderPoint: ExtruderPoint) => {
      return acc + extruderPoint.originalString + '\n'
    }, '');
    if(selectionGcode) {
      setHighlightedGCode(selectionGcode);
    }
  };

  const handleCanvasClick = () => {
    setActiveLayer(-1);
    setHighlightedGCode('');
  };



  const [preview, setPreview] = useState<GCodePreview.WebGLPreview | undefined>(undefined)
  const parentRef = useRef<HTMLDivElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [observer, setObserver] = useState<ResizeObserver | null>(null)

  useEffect(() => {    
    const canvas: HTMLCanvasElement | null = canvasRef.current
    if (!canvas || !rawGcode) return
  
    if (isVisible) {
      setLoadingPreview(true)
      console.log('preview loading set to true')
      const pixelRatio = 0.1
      const rect = canvas.getBoundingClientRect();
      if (rect.width === 0 || rect.height === 0) return
      canvas.width = Math.floor(rect.width * pixelRatio)
      canvas.height = Math.floor(rect.height * pixelRatio)
      try {
        const _preview = GCodePreview.init({
          canvas: canvas,
          extrusionColor: ['hotpink', 'indigo', 'lime'],
          extrusionWidth: 1.8,
          backgroundColor: new THREE.Color('#F5F5F5'),
          lineHeight: miniPreview ? 1.4 : 0.8,
          renderExtrusion: true,
          // disableGradient: true,
          renderTubes: showModel,
          lineWidth: miniPreview ? 3 : 2,
          renderTravel: miniPreview ? false : true,
          buildVolume: miniPreview ? undefined : {
            x: 240,
            y: 210,
            z: 210,
          },
          devMode: false,
        });
        _preview.canvas.width = Math.floor(rect.width * pixelRatio)
        _preview.canvas.height = Math.floor(rect.height * pixelRatio)
        _preview.controls.enableZoom = !miniPreview


        const _observer: ResizeObserver = new ResizeObserver(() => {
          const rect = canvas.getBoundingClientRect();
          _preview.canvas.width = Math.floor(rect.width * pixelRatio)
          _preview.canvas.height = Math.floor(rect.height * pixelRatio)
          _preview.resize();
        });
        _observer.observe(canvas);
        setObserver(_observer);
      
        _preview.processGCode(rawGcode);
      
        if (miniPreview) {
          const box = new THREE.Box3().setFromObject(_preview.scene);
          const center = new THREE.Vector3();
          box.getCenter(center);

          const size = new THREE.Vector3();
          box.getSize(size);

          // Center the controls on the model
          _preview.controls.target.copy(center);
          _preview.controls.update();

          // Position the camera so the model fits nicely
          const maxDim = Math.max(size.x, size.y, size.z);
          const fov = _preview.camera.fov * (Math.PI / 180); // convert vertical fov to radians

          // Calculate distance required to fit the entire bounding box vertically
          let cameraZ = Math.abs(maxDim / (2 * Math.tan(fov / 2)));

          // Optional: Add some offset so it's not too tight
          cameraZ *= 1.1;

          _preview.camera.position.set(center.x, center.y, center.z + cameraZ);

          _preview.camera.lookAt(center);
          _preview.controls.update();
        }

        setLoadingPreview(false);
        setPreview(_preview);
      } catch (error) {
        console.log("Error creating gcodeprievew")
      }
      
    } else {
      if (preview?.renderer) {
        const gl = preview.renderer.getContext();
        const loseContextExt = gl.getExtension('WEBGL_lose_context');
        if (loseContextExt) {
          loseContextExt.loseContext();
          console.log('WebGL context explicitly lost');
        }
      }
      
      preview?.clear()
      preview?.dispose?.()
      setLoadingPreview(false)
      setPreview(undefined)
    }
  }, [rawGcode, isVisible]);

  useEffect(() => {  
    if (preview?.renderer) {
      const gl = preview.renderer.getContext();
      const loseContextExt = gl.getExtension('WEBGL_lose_context');
      if (loseContextExt) {
        loseContextExt.loseContext();
        console.log('WebGL context explicitly lost');
      }
    }
    preview?.clear(); // Ensure GCodePreview has a clear method
    preview?.dispose?.(); // If there's a dispose method

    // Remove observer
    observer?.disconnect();


    // Cleanup state
    setPreview(undefined);
    setObserver(null);
  },[])

  useLayoutEffect(() => {
      if (!parentRef.current) {
        return
      }
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting)
        },
        { threshold: 0.1 } // Trigger when 10% of the container is visible
      );
  
      if (parentRef.current) {
        observer.observe(parentRef.current);
      }
  
      return () => {
        if (parentRef.current) {
          observer.unobserve(parentRef.current);
        }
      };
    }, [parentRef.current]);
  

  useEffect(() => {
    if (preview) {
      preview.renderTubes = showModel
      
      if (showGrid) {
        preview.buildVolume = {
          x: 240,
          y: 210,
          z: 210
        }
      } else {
        preview.buildVolume = undefined
      }
      preview.render()
    }
  }, [showModel, showGrid])
  
  

  if (gcodeFile && gcodeFile.fileSize > 3 * 1024 * 1024) {
    return (
      <div className='flex items-center justify-center w-full h-full'>
        <div className='text-sm'>
          Sorry, 
          {miniPreview ? ' the file ' : <a href={gcodeFile.presignedUrl} target='_blank' className=''> <span className='underline'>{gcodeFile.fileName}</span> </a> } 
          is to big to preview.
        </div>
      </div>
    )
  } 

  return (
    <div className='flex w-full h-full flex-col items-center relative' ref={parentRef}>
      {isVisible &&
        <canvas 
          className={`relative top-0 left-0 w-full h-full`}
          width='100%' 
          height='100%' 
          ref={canvasRef}/>
      }
      {(!model || loadingPreview) && 
        <div className='absolute w-full h-full top-0 left-0 flex items-center justify-center'>
          <Loader className='h-4 w-4 animate-spin' />
        </div>
      }
      <div className={`absolute w-64 justify-between flex text-bw-green pulse flex-col items-center h-16 m-auto left-0 right-0 top-0 bottom-0 p-4 bg-bw-background-grey shadow-2xl rounded-sm z-50 text-xs ${progressGeometry === 0 || progressGeometry === 100 ? 'hidden' : ''}`}>
        <div className='animate-pulse'>Building the print preview ...</div>
        <ProgressBar className='w-full' height={6} progress={progressGeometry} bright/>
      </div>
      <div className={`viewer-controls ${miniPreview && 'hidden'}`}>
        <div className={`absolute m-auto left-0 right-0 bottom-16 z-50 text-sm w-64 flex flex-col gap-2 items-center bg-bw-background-grey rounded-md p-4 shadow-2xl ${(activeLayer !== -1 && !showModel) ? '' : 'hidden'}`}>
          Edit layer: {activeLayer}
            <Button variant='bwsecondary' className='w-full' disabled>Currently unavailable</Button>
        </div>
        <div className='absolute bottom-8 left-8 text-xs z-50 flex flex-col gap-2'>
          <div className='flex flex-col p-4 rounded-xl bg-white text-nowrap gap-3'>
            <div className='flex justify-between gap-1 font-bold items-center'>
              Print Preview (p)
              <Switch checked={showModel ? true : false} onCheckedChange={(checked) => setShowModel(checked)} tabIndex={10} title='Show extruded model'/>
            </div>
            {/* <div className='flex gap-1 justify-between pl-2 items-center mb-0'>
              Filament matt
              <Switch checked={gcodeFilamentMatt ? true : false} onCheckedChange={(checked) => setGcodeFilamentMatt(checked)} tabIndex={10} title='Show extruded model'/>
            </div>
            <div className='flex justify-between gap-1 pl-2 items-center'>
              Filament colour
              <Popover>
                <PopoverTrigger className='relative' asChild >
                  <Button variant="link" className='relative h-auto' size="icon">
                    <div className={`w-4 h-4 ml-3 border border-bw-green/10 rounded-xl`} style={{backgroundColor: gcodeFilamentColor}}></div>
                  </Button>
                </PopoverTrigger>
                  <PopoverContent align='start'>
                      <CirclePicker 
                        colors={['#FFFEF3', '#020407', '#31ADE2', '#46C6B4', '#FFCD59', 
                          '#FF5E63', '#D7942E', '#AA5042', '#ECE7D7', '#B8CDB6', 
                          '#DFBAA9', '#003272', '#A0A0A0', '#545151']}
                        circleSize={20}
                        onChange={(color) => filamentColourChanged(color)}/>
                  </PopoverContent>
              </Popover>
            </div> */}
          </div>
          <div className='flex flex-col p-4 rounded-xl bg-white text-nowrap'>
            <div className='flex gap-1 font-bold items-center justify-between'>
              Floor Grid (g)
              <Switch checked={showGrid ? true : false} onCheckedChange={(checked) => setShowGrid(checked)} tabIndex={10} title='Toggle floor grid'/>
            </div>
          </div>
          <div className='flex flex-col p-4 rounded-xl bg-white max-w-64'>
            <div>{gcodeFile?.fileName}</div>
            <div>Nozzle Size: {gcodeFile?.fileattributesmodelSet ? gcodeFile?.fileattributesmodelSet[0]?.nozzleSize + ' mm' : 'n/a'}</div>
            <div>Print Time: {gcodeFile?.fileattributesmodelSet ? gcodeFile?.fileattributesmodelSet[0]?.printTime + ' mins' : 'n/a'}</div>
            <div>Print Weight: {gcodeFile?.fileattributesmodelSet ? gcodeFile?.fileattributesmodelSet[0]?.printWeight + ' g' : 'n/a' }</div>
            <div>W {model ? (model.modelBounds.x.max - model.modelBounds.x.min) : 'undefined'} mm</div>
            <div>H {model ? (model.modelBounds.z.max - model.modelBounds.z.min) : 'undefined'} mm</div>
            <div>D {model ? (model.modelBounds.y.max - model.modelBounds.y.min) : 'undefined'} mm</div>
            <div>Total layers: {model?.layers.length}</div>
          </div>
        </div>
      </div>
      {/* {miniPreview && <Canvas shadows={{ type: THREE.PCFSoftShadowMap, enabled: !miniPreview}} gl={{toneMapping: THREE.ACESFilmicToneMapping}} frameloop='demand' dpr={miniPreview ? 1 : 1} onPointerMissed={handleCanvasClick} camera={{up: [0, 0, 1]}}>
        <ambientLight intensity={0.23} />
        <spotLight intensity={1.3} castShadow position={new THREE.Vector3(-100, 200, 1200)} distance={2000} decay={1} color={0xffeeee} />
        <spotLight intensity={1} castShadow position={new THREE.Vector3(500, 500, 1800)} distance={2000} decay={1} color={0xffffff} />
        <Environment preset={'warehouse'} blur={0.8} />
        {!miniPreview && <Floor grid={showGrid} size={floorSize} />}
        {showModel ?
          geometry ?
            <mesh castShadow receiveShadow geometry={geometry} ref={meshRef} material={shaderMaterial}/>
            : null
          :
          modelToThreeLines(model).filter(line => line.length > 1).map((line, i) => {
            return (
              <Line
                key={i}
                ref={lineModel}
                points={line}
                color={i === activeLayer ? "red": (i === hoveredLine ? "blue":"#333333")}
                lineWidth={
                  miniPreview
                    ? 0.5
                    : i === activeLayer
                      ? 3
                      : 1.5
                }
                onClick={() => handleLineClick(i)}
                onPointerOver={() => setHoveredLine(i)}
                onPointerOut={() => setHoveredLine(-1)}
              />
            )})
        }
        <PerspectiveCamera 
          ref={camera} 
          makeDefault 
          position={[0, 0, 200]} // Set initial position
          fov={30}
          near={0.1}
          far={5000}
          up={[0, 0, 1]}
        />
        {camera?.current &&
          <>
            <OrbitControls 
              ref={orbitControlsRef}
              enablePan={!miniPreview}
              enableZoom={!miniPreview}
              enableDamping={!miniPreview}
              zoomSpeed={0.35}
              target={cameraOrbit}
              // autoRotate={miniPreview}
              // autoRotateSpeed={0.25}
              camera={camera.current}
            />
          </>
        }
      </Canvas>} */}
    </div>
  );
}

export default GCodeViewer;
