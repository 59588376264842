  import React, {useEffect, useState} from "react"
import { useNavigate } from "react-router-dom";
import { Check, ChevronDown, ChevronsUpDown, Clock, GitCommit, Loader, Plus } from "lucide-react"
import api from '@/global-components/api'

import { cn } from "@/lib/utils"
import { Button } from "@/global-components/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandSeparator,
  CommandItem,
} from "@/global-components/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/global-components/components/ui/popover"
import CreateVersion from './CreateVersion'
import { useMutation, useQuery } from '@apollo/client';

import { Version, FileType } from '@/global-components/types'
import helpers from "@/global-components/components/helpers";

type VersionSelectProps = {
  productRef: string | undefined;
  currentVersionId: string | undefined;
  existingFiles: FileType[]
  isLatestVersion: boolean;
  selectCallback: Function;
  allowNewVersion?: boolean;
}


const VersionSelect = ({productRef, currentVersionId, existingFiles, isLatestVersion, selectCallback, allowNewVersion=true}: VersionSelectProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)
  const [value, setValue] = useState<string | undefined>(currentVersionId)
  const [versions, setVersions] = useState<Version[]>([])
  const [dropdownTitle, setDropdownTitle] = useState<string>('')

  const navigate = useNavigate();
  
  const versionsQuery = useQuery(api.products.queries.GET_VERSIONS_FOR_PRODUCT_REF, {
    variables: { reference: productRef },
    pollInterval: 5000,
    skip: !productRef
  })

  

  const newVersionCreated = () => {
    versionsQuery.refetch({ fetchPolicy: 'network-only' })
    selectCallback(true);
    setOpen(false)
  }

  const navigateToVersion = (versionId: string) => {
    const versionNumber: number | undefined = versions.find((version: Version) => version.versionId === versionId)?.versionNumber
    if (versionNumber) {
      navigate('/product/' + productRef + '?v=' + versionNumber)
    }
  }

  useEffect(() => {
    versionsQuery.refetch({ fetchPolicy: 'network-only' })
    if (isLatestVersion) {
      setDropdownTitle(versions.find((version: Version) => version.versionId === currentVersionId)?.versionTag + ' (Latest Version)')
    } else {
      setDropdownTitle(versions.find((version: Version) => version.versionId === currentVersionId)?.versionTag + ' (Version ' + versions.find((version: Version) => version.versionId === currentVersionId)?.versionNumber + ')')
    }
  }, [versions, isLatestVersion, currentVersionId])

  useEffect(() => {
    if (versionsQuery.data?.versionsForProductRef) {
      const sortedVersions = versionsQuery.data?.versionsForProductRef?.slice().sort((a: Version, b: Version) => {
        return b.versionNumber - a.versionNumber
      })
      setVersions(sortedVersions)      
    }
  }, [versionsQuery])

  useEffect(() => {
    setValue(currentVersionId);
  }, [currentVersionId])

  return (
    <div className='absolute right-6 top-[80px] z-10'>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            disabled={versionsQuery.loading}
            className="w-[350px] justify-between bg-transparent pl-3 pr-2 "
          >
            <div className="flex gap-2 items-center truncate">
              {versionsQuery.loading ?
                <Loader className="h-4 w-4 shrink-0 animate-spin" />
                : <GitCommit className="h-4 w-4 shrink-0" />
              }
              {versions.length 
                ? dropdownTitle
              : 'Version 1'}
            </div>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={open ? "w-[350px] p-0" : "w-[350px] p-0 bg-transparent"}>
          <Command className="bg-transparent data-[state=selected]:bg-bw-pale-sage">
            {/*<CommandInput placeholder="Search projects ..." />*/}
            <CommandEmpty>No previous versions</CommandEmpty>
            <CommandGroup>
              <CreateVersion callback={() => newVersionCreated()} productRef={productRef} existingFiles={existingFiles} disabled={!allowNewVersion}/>
              <CommandSeparator className='my-2'/>
              {versions.map((version: Version) => (
                <CommandItem
                  key={version.versionId}
                  value={version.versionId}
                  onSelect={(currentValue) => {
                    if (currentValue === value) {
                      return;
                    } else {
                      setValue(currentValue)
                      selectCallback(currentValue);
                      navigateToVersion(currentValue);
                      setOpen(false)
                    }
                  }}
                  className={value === version.versionId ? 'cursor-default items-center' : 'items-center'}
                >
                  <div className="flex flex-col gap-0 w-full">
                    <div className="flex items-center justify-between gap-2">
                      <div className={`${value === version.versionId ? 'font-medium' : ''}`}>{version.versionTag}</div>  
                    </div>
                    <div className={`${value === version.versionId ? 'opacity-100' : 'opacity-30'} whitespace-nowrap flex items-center text-xs gap-1`}>
                      <Clock className="h-3 w-3" />
                      {helpers.formatDateToDDMMYY(version.createdAt)}
                    </div>
                    {/* <div className={`text-xs ${value === version.versionId ? 'text-bw-green font-medium' : 'text-bw-green/30'}`}>{version.versionTag ? version.versionTag : 'No description' }</div> */}
                  </div>
                  <Check
                    className={cn(
                      "h-4 w-4",
                      value === version.versionId ? "opacity-100" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}


export default VersionSelect;