// React imports
import { useState } from 'react';
import { useMutation } from '@apollo/client';

// Context and API imports
import api from '@/global-components/api'

// Component imports
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/global-components/components/ui/dialog";
import { Input } from '@/global-components/components/ui/input'
import { Button } from "@/global-components/components/ui/button";
import { useToast } from "@/global-components/components/ui/use-toast";
import { Loader, Plus } from "lucide-react";


const CreateGroup = (props: any) => {
  const [open, setOpen] = useState(false)
  const [formValid, setFormValid] = useState<boolean>(false)
  const [newGroupTitle, setNewGroupTitle] = useState<string>('');
  const [createGroup, {loading: creatingGroup}] = useMutation(api.products.mutations.CREATE_GROUP);
  const { toast } = useToast()

  const submitCreateGroup = () => {
    createGroup({ variables: { groupTitle: newGroupTitle }})
        .then((result : any) => {
          
          toast({
            title: "Group created successfully",
            variant: 'success',
            duration: 3000
          })
          if(props.callback) {
            props.callback(result.data.createGroup.createdGroup);
          }
          setOpen(false);
        })
        .catch((error : any) => { })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant='bwsecondary' size="sm" className='w-full text-center items-center'><Plus className='h-4 w-4 mr-1' /> Create New Project</Button>
      </DialogTrigger>
      <DialogContent className='max-w-l'>
        <DialogHeader>
          <DialogTitle>Create a new Project</DialogTitle> 
        </DialogHeader>
        <Input type='text' name='productTitle' placeholder="Product title" value={newGroupTitle} onChange={(e) => setNewGroupTitle(e.target.value)} />
        <DialogFooter>
          <Button variant="bwsecondary" disabled={creatingGroup} onClick={() => setOpen(false)}>Cancel</Button>
          <Button disabled={formValid || creatingGroup} variant="bwconfirm" onClick={submitCreateGroup}>
            {creatingGroup
              ? <div className='flex items-center gap-1'>
                  <Loader className='h-4 w-4 animate-spin'/>
                  Creating Project
                </div>
              : 'Create Project'
            }
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default CreateGroup;