"use client"

import * as React from "react"
import { useNavigate } from 'react-router-dom';
import { Check, ChevronDown, ChevronsUpDown, ExternalLink, Folder, Plus, XCircle } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/global-components/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandSeparator,
  CommandItem,
} from "@/global-components/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/global-components/components/ui/popover"
import CreateGroup from '../createGroup/CreateGroup'
import GroupOrgs from '@/components/productRelated/groupOrgs/GroupOrgs'
import { ProductGroup } from "@/global-components/types"
import { QueryResult, useMutation, useQuery } from '@apollo/client'
import { useToast } from "@/global-components/components/ui/use-toast";
import api from '@/global-components/api'
import useUserStore from "@/context/useUserStore";

type GroupSelectProps = {
  productGroup: ProductGroup | undefined;
  selectCallback: Function;
  access: boolean;
}


const GroupSelect = ({productGroup, selectCallback, access}: GroupSelectProps): JSX.Element | null => {
  const { user } = useUserStore();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState<boolean>(false)
  const [value, setValue] = React.useState<string | undefined>(productGroup?.groupId)
  const [userGroups, setUserGroups]  = React.useState<ProductGroup[]>([])
  const [allGroups, setAllGroups] = React.useState<ProductGroup[]>([])
  const groupsQuery: QueryResult = useQuery(api.products.queries.GET_GROUPS, {
    variables: {
      notifyOnNetworkStatusChange: true,
    }
    });
  const [deleteGroup] = useMutation(api.products.mutations.DELETE_GROUP)

  React.useEffect(() => {
    if (groupsQuery.data?.groups) {
      const rawGroups: ProductGroup[] = 
        groupsQuery.data.groups.find((group: ProductGroup) => group.groupId === productGroup?.groupId) 
          ? groupsQuery.data.groups 
          : [productGroup, ...groupsQuery.data.groups]

      const groups: ProductGroup[] = rawGroups
        .filter((group: ProductGroup) => (group.createdBy.userId === user?.userId || group.groupId === productGroup?.groupId))

      setAllGroups(groups)
    }
  }, [groupsQuery.data, groupsQuery])


  const newGroupCreated = (newGroup: ProductGroup) => {
    groupsQuery.refetch().then(() => {
      setValue(newGroup.groupId)
    })
    selectCallback(newGroup.groupId)
    setOpen(false)
  }

  const handleDeleteGroup = (id: string) => {
    deleteGroup({variables: {groupId: id}})
      .then((result) => {
        if (result.data?.deleteGroup.success) {
          toast({
            title: "Project has been deleted. All products have been left untouched.",
            variant: "success",
            duration: 2000
          })
          groupsQuery.refetch()
        } else {
          toast({
            title: "Something went wrong",
            variant: "destructive",
            duration: 2000
          })
        }
      })
  }

  React.useEffect(() => {
    setValue(productGroup?.groupId);
  }, [productGroup, allGroups])

  return (
    <div className='absolute right-6 top-[30px] z-10'>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            disabled={!access}
            className="w-[350px] justify-between bg-transparent pl-3 pr-2 border border-solid"
          >
            <div className="flex gap-2 items-center">
              <Folder className="h-4 w-4" />
              {value
                ? 'Project: ' + allGroups.find((group) => {
                  return group.groupId === value
                })?.groupTitle
                : "Not part of project ..."}
            </div>
            <ChevronDown className="h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={open ? "w-[350px] p-0" : "w-[350px] p-0 bg-transparent"}>
          <Command className="bg-transparent">
            {/*<CommandInput placeholder="Search projects ..." />
            <CommandEmpty>No project found.</CommandEmpty>*/}
            <CommandGroup>
              {allGroups.map((group) => (
                <div
                  key={group.groupId}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    const target = e.target as HTMLElement
                    if (e.target !== e.currentTarget && !target.classList.contains('group-title')) {
                      return
                    }
                    if (group.groupId === value) {
                      return;
                    } else {
                      setValue(group.groupId)
                      selectCallback(group.groupId);
                      setOpen(false)
                    }
                  }}
                  className={`flex items-center w-full p-2 pl-4 pr-4 text-sm justify-between cursor-pointer rounded-xl group border border-transparent hover:bg-bw-green/5 hover:border-bw-green/10
                    ${value === group.groupId ? ' cursor-default' : ''} 
                    ${group.createdBy?.userId !== user?.userId && ' opacity-20'}`}
                >
                  <div className={`group-title truncate ${group.groupId === value ? ' font-bold':' cursor-default'}`}>
                    {group.groupTitle}
                  </div>
                  <div className={`flex items-center gap-1 ${group.groupId === value ? 'opacity-100' : 'opacity-100'}`}>
                    <div className="opacity-0 group-hover:opacity-100 group-hover:pointer-events-auto">
                      {!group.defaultGroup && <GroupOrgs group={group} refetchGroup={() => groupsQuery.refetch()} groupLoading={groupsQuery.loading}/> }
                    </div>
                    <div className="opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                      <Button variant='minimal' size='sm' className='opacity-70 hover:opacity-100' onClick={() => navigate('/project/' + group.groupId)}>
                        <ExternalLink className="h-4 w-4" />
                      </Button>
                    </div>
                    {value === group.groupId ?
                      <Check
                        className={cn(
                          "h-4 w-4",
                          value === group.groupId ? "opacity-100" : "opacity-0"
                        )}
                      />
                      :
                      <Button variant='minimal' size='sm' className="p-0 text-ui-denial-red">
                        {group.defaultGroup ?
                          <XCircle className="opacity-0 h-4 w-4" />
                          :
                          <XCircle
                            className="h-4 w-4 opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto" onClick={() => handleDeleteGroup(group.groupId)}
                          />
                        }
                      </Button>
                  }
                  </div>
                </div>
              ))}
                <CommandSeparator className='my-2'/>
                <CreateGroup callback={(newGroup: ProductGroup) => newGroupCreated(newGroup)} />
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}


export default GroupSelect;