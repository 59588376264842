import { useState } from 'react'
import { FileType } from '../types'

const debug: boolean = process.env.REACT_APP_BRANCH === 'beta' ? false : true

/*const log = (message: any) => {
  if (debug) {
    console.trace(message);
  }
};*/

const log = debug ? console.log.bind(window.console) : () => {}

const dateAndTime = (datetime: string): string => {
  const date = new Date(datetime)
  return date.toLocaleString()
}

const formatDateToDDMMYY = (
  dateObject: Date | string | undefined,
  justDate?: boolean,
  humanise?: boolean,
  shorten?: boolean,
): string => {
  if (dateObject === undefined) {
    return 'n/a';
  } else if (!(dateObject instanceof Date)) {
    dateObject = new Date(dateObject);
  }

  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - dateObject.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);

  // Extract date components
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear().toString().slice(2);
  const fullYear = dateObject.getFullYear();

  // Extract time components
  const hours = dateObject.getHours().toString().padStart(2, '0');
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');

  if (!day || day === 'NaN') {
    return '';
  }

  // Check if it's today or yesterday using date comparisons
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const dateToCheck = new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate());

  // Human-readable formatting
  if (humanise) {
    if (diffInSeconds < 60) {
      return 'Just now';
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} min${diffInMinutes > 1 ? 's' : ''} ago`;
    } else if (dateToCheck.getTime() === today.getTime()) {
      return `Today, ${hours}:${minutes}h`;
    } else if (dateToCheck.getTime() === yesterday.getTime()) {
      return `Yesterday${shorten ? '': ', ' + hours + ':' + minutes + 'h'}`;
      return `Yesterday, ${hours}:${minutes}h`;
    } else if (fullYear === now.getFullYear()) {
      // Format: DD Month at XX:XXh (for this year)
      const monthNames = [
        'Jan', 'Feb', 'March', 'April', 'May', 'June',
        'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
      return `${day} ${monthNames[dateObject.getMonth()]}${shorten ? '': ' at ' + hours + ':' + minutes + 'h'}`;
    } else {
      // Format: DD.MM.YY at XX:XXh (for other years)
      return `${day}.${month}.${year} at ${hours}:${minutes}h`;
    }
  }

  // Standard formatting
  if (justDate) {
    return `${day}.${month}.${year}`;
  } else {
    return `${day}.${month}.${year} at ${hours}:${minutes}:${dateObject.getSeconds().toString().padStart(2, '0')}`;
  }
};


const formatSecondsIntoHHMMSS = (seconds: number): string => {
  if (seconds < 0) {
    throw new Error('Seconds must be a non-negative number.')
  }

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')} h`
  }

  if (minutes > 0) {
    return `${minutes}:${secs.toString().padStart(2, '0')} mins`
  }

  return `${secs} s`
}

const extractFileTypeFromFilename = (filename: string | undefined): string => {
  if (!filename) {
    return ''
  }
  const splitName = filename.toLowerCase().split('.')
  // console.log(splitName)
  return splitName.length > 1
    ? splitName[splitName.length - 1].toLowerCase()
    : ''
}

const extractFileTypeFromUrl = (url: string): string => {
  const urlObject = new URL(url)
  return extractFileTypeFromFilename(urlObject.pathname)
}

const isFileGcode = (file: FileType | File): boolean => {
  return (
    extractFileTypeFromFilename(
      file instanceof File ? file.name : file.fileName
    ) === 'gcode'
  )
}

const isFileStl = (file: FileType | File): boolean => {
  return (
    extractFileTypeFromFilename(
      file instanceof File ? file.name : file.fileName
    ) === 'stl'
  )
}

const isFileImage = (file: FileType | File): boolean => {
  return ['jpg', 'jpeg', 'png'].includes(
    extractFileTypeFromFilename(
      file instanceof File ? file.name : file.fileName
    )
  )
}

const isOtherFile = (file: FileType | File): boolean => {
  return (
    extractFileTypeFromFilename(
      file instanceof File ? file.name : file.fileName
    ) !== 'stl' &&
    extractFileTypeFromFilename(
      file instanceof File ? file.name : file.fileName
    ) !== 'gcode' &&
    extractFileTypeFromFilename(
      file instanceof File ? file.name : file.fileName
    ) !== 'jpg' &&
    extractFileTypeFromFilename(
      file instanceof File ? file.name : file.fileName
    ) !== 'jpeg' &&
    extractFileTypeFromFilename(
      file instanceof File ? file.name : file.fileName
    ) !== 'png'
  )
}

const isEmailValid = (_email: string): boolean => {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(_email)
    ? true
    : false
}

const setLocalStorageItem = (key: string, value: string): void => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.warn(`Error setting localStorage key “${key}”:`, error)
  }
}

const getLocalStorageItem = (key: string): string | null => {
  try {
    const item = window.localStorage.getItem(key)
    return item ? JSON.parse(item) : null
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error)
    return null
  }
}

const openInNewTab = (url: string) => {
  const _url: string = url.includes('http') ? url : 'http://' + url
  window.open(_url, '_blank', 'noreferrer')
}

const removeUnusedFilesFromLocalStorage = () => {
  const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000 // 3 days in milliseconds
  const now = Date.now()

  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith('file_lastUsed_')) {
      const lastUsedTime: string | null = localStorage.getItem(key)
      if (lastUsedTime) {
        if (now - parseInt(lastUsedTime) > threeDaysInMillis) {
          const fileId = key.replace('file_lastUsed_', '')
          localStorage.removeItem(`file_${fileId}`)
          localStorage.removeItem(`file_lastUsed_${fileId}`)
        }
      }
    }
  })
}

const isValidUrl = (url: string | undefined) => {
  if (url === undefined) {
    return false
  }
  const urlRegex = /^(http:\/\/|https:\/\/|www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
  return urlRegex.test(url)
}

const shortenString = (input: string, maxLength: number = 40): string => {
  if (input.length <= maxLength) {
    return input
  }
  const ellipsis = '...'
  const truncatedLength = maxLength - ellipsis.length
  return input.substring(0, truncatedLength) + ellipsis
}

const returnStatusColour = (status: string): string => {
  const lowerStatus = status.toLowerCase();

  if (lowerStatus.includes("busy")) {
    return " bg-ui-grey animation-pulse";
  }
  if (lowerStatus.includes("ready") || lowerStatus.includes("operational")) {
    return " bg-ui-confirmation-green";
  }
  if (lowerStatus.includes("printing")) {
    return " border border-[3px] border-t-0 border-r-0 border-l-0 border-ui-confirmation-green animate-pulse animate-spin";
  }
  if (lowerStatus.includes("inactive") || lowerStatus.includes("pause") || lowerStatus.includes("inoperational")) {
    return " bg-bw-grey";
  }
  if (lowerStatus.includes("error")) {
    return " bg-ui-denial-red";
  }
  if (lowerStatus.includes("idle")) {
    return " bg-ui-denial-red animate-pulse";
  }
  if (lowerStatus.includes("cancelling")) {
    return " border border-2 border-t-0 border-r-0 border-ui-warn-orange animate-pulse animate-spin";
  }
  
  return "";
};

const isMobile = (): boolean => {
  const userAgent = navigator.userAgent
  const isMobileDevice = /Mobi|Android|iPhone/i.test(userAgent)
  const isTablet = /Tablet|iPad/i.test(userAgent)
  const isSmallScreen = window.innerWidth <= 768

  return isMobileDevice && !isTablet && isSmallScreen
}

const helpers = {
  dateAndTime,
  formatDateToDDMMYY,
  formatSecondsIntoHHMMSS,
  extractFileTypeFromUrl,
  extractFileTypeFromFilename,
  setLocalStorageItem,
  getLocalStorageItem,
  openInNewTab,
  log,
  isEmailValid,
  removeUnusedFilesFromLocalStorage,
  isValidUrl,
  returnStatusColour,
  shortenString,
  isMobile,
  filters: {
    isFileGcode,
    isFileStl,
    isFileImage,
    isOtherFile,
  },
}

export default helpers
