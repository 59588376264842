import React, { useEffect, useState, MouseEvent, useRef } from 'react';
import STLViewer from '../STLViewer/STLViewer';
import helpers from "@/global-components/components/helpers"
import PrintProduct from '../productRelated/printProduct/PrintProduct';
import { Badge } from "@/global-components/components/ui/badge";
import { FileType, ProductType } from '@/global-components/types';
import { Building, Loader } from 'lucide-react';
import ProductDelete from '@/views/product/productDelete/ProductDelete';
import GCodeViewer from '@/global-components/components/bw/gCodeViewer/GCodeViewer';
import useUserStore from '@/context/useUserStore';
import { useQuery } from '@apollo/client';
import api from '@/global-components/api';
import { Button } from '@/global-components/components/ui/button';
import { useNavigate } from 'react-router-dom';

type ProductStatus = 'unapproved' | 'approved' | 'production';

type ProductPreviewProps = {
  product: ProductType;
  navigate: Function;
  index: number;
  showOrg?: boolean;
  disabled?: boolean;
  hideDelete?: boolean;
  callback?: Function;
}

const ProductPreview = (props: ProductPreviewProps) => {
  const { userPreferences } = useUserStore()
  const navigate = useNavigate()
  const [wasDragging, setWasDragging] = useState<boolean>(false)
  const [printButtonPressed, setPrintButtonPressed] = useState<boolean>(false)
  const deleteProductButton = useRef<HTMLElement | null>(null)
  const numberUnreadMessages: number = props.product.messagemodelSet ?
    props.product.messagemodelSet?.filter((message: any) => !message.read)?.length : 0

  const [product, setProduct] = useState<ProductType | null>(null)
  const [firstStl, setFirstStl] = useState<any>(null)
  const [firstGcode, setFirstGcode] = useState<any>(null)

  const { client, data: productFilesQueryData, previousData: prevProductFilesQueryData, loading: productFilesQueryLoading} = useQuery(api.products.queries.GET_FILES_FOR_PRODUCT, {
    variables: {
      productId: product?.productId,
    },
    skip: !product,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    returnPartialData: true,
  })

  useEffect(() => {
    if (productFilesQueryData?.filesForProduct) {
      client.writeQuery({
        query: api.products.queries.GET_FILES_FOR_PRODUCT,
        variables: { productId: product?.productId },
        data: { filesForProduct: productFilesQueryData.filesForProduct },
      });
    }
  }, [productFilesQueryData, client]);

  useEffect(() => {
    setProduct(props.product)
  }, [props.product])

  useEffect(() => {
    const files: FileType[] = prevProductFilesQueryData?.filesForProduct || productFilesQueryData?.filesForProduct || []
    if (files.length > 0) {
      setFirstStl(files.find((filemodelSet: any) => helpers.extractFileTypeFromFilename(filemodelSet.fileName) === 'stl'))
      setFirstGcode(files.find((filemodelSet: any) => helpers.extractFileTypeFromFilename(filemodelSet.fileName) === 'gcode'))
    }
  }, [prevProductFilesQueryData, productFilesQueryData])

  const mouseDown = (event: MouseEvent<HTMLDivElement>) => {
    setWasDragging(false);
  }

  const mouseMove = (event: MouseEvent<HTMLDivElement>) => {
    setWasDragging(true);
  }


  const goToProduct = (event: MouseEvent<HTMLDivElement>) => {
    const target: HTMLElement = event.target as HTMLElement;
    if(wasDragging || printButtonPressed || deleteProductButton.current?.contains(target)) {
      return;
    } else {
      props.navigate('/product/' + props.product.productVersion[0].reference);
    }
  }

  const printButtonMouseDown = () => {
    setPrintButtonPressed(true);
  }

  const showPrintModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  }

  if (!props.product.productVersion[0]) {
    return null
  }
  
  return (
    <div className={`product-preview type normal group/productpreview transition-all duration-100 easing-kibu hover:scale-[1.03]
      ${props.showOrg ? 'bg-white' : 'bg-white'}
      ${props.disabled && 'pointer-events-none'}`} 
      onMouseMove={mouseMove} onMouseDown={mouseDown} onMouseUp={goToProduct} key={props.index}>
      {numberUnreadMessages > 0 ? <div className='notification-badge text-xxs font-bold bg-ui-notification-red text-white'>{numberUnreadMessages}</div> : null}
      
      <div className='absolute top-2 right-2 flex justify-end items-center gap-1 z-10'>
        <div className={`opacity-0 h-fit flex items-center transition-all duration-100 group-hover/productpreview:opacity-100 ${props.hideDelete && 'hidden'}`}>
          <ProductDelete forwardRef={deleteProductButton} reference={product?.productVersion[0].reference} buttonVariant='inline' buttonSize='zeromargin' callback={props.callback}/>
        </div>
        <Badge className={product?.statusSeen ? 'w-fit' : 'w-fit status-pulse'}
          variant={product?.status.toLowerCase() as ProductStatus}>
            {product?.status}
        </Badge>
      </div>
      
      <div className='product-preview-header text-xs'>
        
      </div>
      {(userPreferences?.previewGcode) ? 
        <div className={firstGcode ? 'product-preview-visualiser' : 'product-preview-visualiser no-stl'}>
          {firstGcode
            ? <GCodeViewer gcodeFile={firstGcode} nozzleDiameter={firstGcode.fileattributesmodelSet ? firstGcode.fileattributesmodelSet[0]?.nozzleSize : undefined} miniPreview />
            : <div className='text-xs text-bw-green/30'>
              {productFilesQueryLoading ? 
                <Loader className='h-4 w-4 animate-spin' />
                : <div className='text-xs text-bw-green/30 flex justify-center items-center flex-col gap-1'>
                  No GCode File to preview
                  <a 
                    onMouseUp={(e: React.MouseEvent) => {
                      e.stopPropagation()
                      navigate('./account?section=settings')
                    }}
                    className='text-bw-green hover:underline'>
                      Change Default in Settings
                  </a>
                </div>
              }
            </div>
          }
        </div>
        :
        <div className={firstStl ? 'product-preview-visualiser transition-all duration-500 hover:scale-[1.05]' : 'product-preview-visualiser no-stl'}>
          {productFilesQueryLoading && !firstStl && !prevProductFilesQueryData 
            ? <Loader className='h-4 w-4 animate-spin' /> 
            : firstStl 
              ? <STLViewer file={firstStl} miniPreview={true}/>
              : <div className='text-xs text-bw-green/30 flex justify-center items-center flex-col gap-1'>
                  No STL File to preview
                  <a 
                    onMouseUp={(e: React.MouseEvent) => {
                      e.stopPropagation()
                      navigate('./account?section=settings')
                    }}
                    className='text-bw-green hover:underline'>
                      Change Default in Settings
                  </a>
                </div>
          }
        </div>
      }
      <div className='product-preview-footer pl-2 pr-2 pb-1 flex flex-col gap-0'>
        <div className='product-title flex justify-between items-end gap-2 leading-none'>
          <div className='relative -top-0.5'>{props.product.productTitle} </div>
          {(!props.showOrg && props.product.organisations?.length) ? 
            <span className='flex opacity-30 gap-0.5 items-center font-normal'>{product?.organisations?.length}<Building className='h-4 w-4' /> </span>
            : null }
        </div>
        {product?.organisations && props.showOrg ?
          <div className='product-org relative -left-0.5 gap-0.5'>
            {product.organisations?.length === 1 ?
              <div className='flex gap-0 opacity-70 items-center'>
                <Building className='h-4 w-4' />{product.organisations[0].name}
              </div>
              :
              <div className='flex gap-0.5 opacity-70 items-center'>
                <Building className='h-4 w-4' />Multiple organisations ({product.organisations.length})
              </div>
            }
          </div> : null
        }
      </div>
    </div>
  )
}

export default ProductPreview