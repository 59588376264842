type BwSymbolProps = {
  className?: string;
  small?: boolean;
  style?: any;
} 

const BwSymbol = (props: BwSymbolProps) => {
    if(props.small) {
        return (
            <div className={props.className}>
                <svg viewBox="0 0 682 670" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M340.72 0C367.027 0 429.78 10.9173 445.205 22.8759C445.294 22.9445 445.382 23.0132 445.471 23.082C426.404 23.6459 415.551 23.7133 395.213 24.0953C373.416 24.5047 362.517 25 340.72 25C318.923 25 308.023 24.5047 286.226 24.0953C265.888 23.7133 255.035 23.646 235.969 23.082C236.057 23.0132 236.146 22.9445 236.234 22.8759C251.659 10.9173 314.413 0 340.72 0Z" fill="black"/>
                    <path d="M340.72 670C367.027 670 429.78 659.083 445.205 647.124C445.294 647.055 445.382 646.987 445.471 646.918C426.404 646.354 415.551 646.287 395.213 645.905C373.416 645.495 362.517 645 340.72 645C318.923 645 308.023 645.495 286.226 645.905C265.888 646.287 255.035 646.354 235.969 646.918C236.057 646.987 236.146 647.055 236.234 647.124C251.659 659.083 314.413 670 340.72 670Z" fill="black"/>
                    <path d="M177.239 119.15C199.036 120.886 209.935 122.162 231.732 123.528C253.529 124.895 264.428 125.302 286.225 125.981C308.023 126.66 318.921 126.924 340.719 126.924C362.516 126.924 373.415 126.741 395.212 125.981C417.009 125.221 427.908 124.493 449.705 123.126C471.503 121.76 482.401 120.805 504.199 119.15C525.996 117.495 536.895 116.507 558.692 114.85C577.315 113.433 587.988 112.542 604.295 111.444C604.275 111.394 604.254 111.345 604.234 111.295C600.448 101.999 594.817 96.474 588.826 96.029C579.649 95.3472 570.871 94.6453 558.692 93.7193C536.895 92.0614 525.996 91.0743 504.199 89.4189C482.401 87.7636 471.503 86.8088 449.705 85.4426C427.908 84.0762 417.009 83.3474 395.212 82.5879C373.415 81.8281 362.516 81.6444 340.719 81.6444C318.921 81.6444 308.023 81.9085 286.225 82.5879C264.428 83.2669 253.529 83.6741 231.732 85.0405C209.935 86.4066 199.036 87.6834 177.239 89.4189C155.441 91.1547 144.543 92.0614 122.745 93.7193C110.567 94.6454 101.788 95.3472 92.6114 96.029C86.6204 96.474 80.9903 101.999 77.2037 111.294L77.1426 111.444C93.4492 112.542 104.122 113.434 122.745 114.85C144.543 116.507 155.441 117.414 177.239 119.15Z" fill="black"/>
                    <path d="M122.745 235.221C144.542 237.878 155.441 239.271 177.238 241.75C199.035 244.23 209.934 245.702 231.731 247.619C253.529 249.537 264.427 250.33 286.225 251.337C308.022 252.344 318.921 252.69 340.718 252.653C362.515 252.615 373.414 252.194 395.211 251.149C417.009 250.105 427.907 249.31 449.705 247.431C471.502 245.551 482.401 244.23 504.198 241.75C525.995 239.271 536.894 237.727 558.691 235.033C580.488 232.339 591.387 230.656 613.185 228.28C633.651 226.048 644.51 225.048 663.804 223.466C663.796 223.417 663.787 223.367 663.779 223.318C662.344 214.511 658.294 208.454 653.667 208.06C640.074 206.903 629.79 205.902 613.185 204.092C591.387 201.715 580.488 200.033 558.691 197.339C536.894 194.644 525.995 193.101 504.198 190.621C482.401 188.141 471.502 186.82 449.705 184.94C427.907 183.061 417.009 182.267 395.211 181.222C373.414 180.178 362.515 179.756 340.718 179.718C318.921 179.681 308.022 180.027 286.225 181.034C264.427 182.041 253.529 182.835 231.731 184.752C209.934 186.67 199.035 188.141 177.238 190.621C155.441 193.101 144.542 194.494 122.745 197.15C100.947 199.806 90.0488 201.526 68.2514 203.903C51.6588 205.712 41.3779 206.713 27.8 207.869C23.1733 208.263 19.1235 214.322 17.6867 223.129C17.6583 223.303 17.6299 223.478 17.6016 223.652C36.9139 225.235 47.774 226.236 68.2514 228.469C90.0488 230.845 100.947 232.565 122.745 235.221Z" fill="black"/>
                    <path d="M340.725 373.18C362.522 373.18 373.421 372.701 395.218 371.674C417.015 370.649 427.914 370.083 449.711 368.051C471.509 366.018 482.407 364.276 504.205 361.512C526.002 358.749 536.901 357.244 558.698 354.232C580.495 351.221 591.394 349.214 613.191 346.457C634.989 343.7 645.887 342.547 667.685 340.447C670.964 340.131 678.166 339.84 681.026 339.569C681.026 339.569 681.449 335.587 681.444 331.59C681.44 328.344 681.002 323.948 681.002 323.948C677.111 323.584 672.365 323.184 667.685 322.734C645.887 320.634 634.989 319.48 613.191 316.723C591.394 313.966 580.495 311.959 558.698 308.948C536.901 305.936 526.002 304.431 504.205 301.668C482.407 298.904 471.509 297.162 449.711 295.129C427.914 293.097 417.015 292.531 395.218 291.506C373.421 290.48 362.522 290 340.725 290H340.719C318.922 290 308.023 290.48 286.226 291.506C264.429 292.531 253.53 293.097 231.733 295.129C209.935 297.162 199.037 298.904 177.239 301.668C155.442 304.431 144.543 305.936 122.746 308.948C100.949 311.959 90.0499 313.966 68.2526 316.723C46.4553 319.48 35.5566 320.634 13.7593 322.734C9.07892 323.184 4.33287 323.584 0.441708 323.948C0.441708 323.948 0.0041901 328.344 4.57757e-05 331.59C-0.00505416 335.587 0.417687 339.569 0.417687 339.569C3.27772 339.84 10.4793 340.131 13.7593 340.447C35.5566 342.547 46.4553 343.7 68.2526 346.457C90.0499 349.214 100.949 351.221 122.746 354.232C144.543 357.244 155.442 358.749 177.239 361.512C199.037 364.276 209.935 366.018 231.733 368.051C253.53 370.083 264.429 370.649 286.226 371.674C308.023 372.701 318.922 373.18 340.719 373.18H340.725Z" fill="black"/>
                    <path d="M558.691 439.113C536.894 436.457 525.995 435.026 504.198 432.584C482.401 430.142 471.502 428.784 449.705 426.904C427.907 425.024 417.009 424.193 395.211 423.186C373.414 422.179 362.515 421.87 340.718 421.87C318.921 421.87 308.022 422.254 286.225 423.186C264.427 424.117 253.529 424.648 231.731 426.528C209.934 428.407 199.035 430.105 177.238 432.584C155.441 435.064 144.542 436.306 122.745 438.925C100.947 441.544 90.0487 443.301 68.2514 445.677C47.774 447.91 36.9139 448.911 17.6016 450.494C17.6497 450.79 17.6978 451.085 17.7461 451.38C19.1495 459.966 23.096 465.876 27.6078 466.26C41.2811 467.423 51.5806 468.425 68.2514 470.243C90.0487 472.619 100.947 474.376 122.745 476.995C144.542 479.613 155.441 480.856 177.238 483.336C199.035 485.815 209.934 487.512 231.731 489.392C253.529 491.272 264.427 491.803 286.225 492.734C308.022 493.666 318.921 494.05 340.718 494.05C362.515 494.05 373.414 493.741 395.211 492.734C417.009 491.727 427.907 490.895 449.705 489.016C471.502 487.136 482.401 485.778 504.198 483.336C525.995 480.894 536.894 479.463 558.691 476.806C580.488 474.15 591.387 472.47 613.185 470.053C629.888 468.202 640.195 467.101 653.908 465.89C658.411 465.492 662.35 459.589 663.748 451.017L663.772 450.872C644.498 449.275 633.64 448.133 613.185 445.866C591.387 443.45 580.488 441.77 558.691 439.113Z" fill="black"/>
                    <path d="M504.199 546.541C482.401 544.885 471.503 543.704 449.705 542.375C427.908 541.046 417.009 540.657 395.212 539.897C373.415 539.137 362.516 538.613 340.719 538.576C318.921 538.538 308.023 538.948 286.225 539.708C264.428 540.468 253.529 541.046 231.732 542.375C209.935 543.704 199.036 544.697 177.239 546.353C155.441 548.009 144.543 548.996 122.745 550.654C104.122 552.071 93.4492 552.963 77.1426 554.061C77.1644 554.115 77.1863 554.169 77.2082 554.222C80.992 563.513 86.6178 569.036 92.6043 569.481C101.784 570.163 110.563 570.865 122.745 571.792C144.543 573.45 155.441 574.438 177.239 576.094C199.036 577.749 209.935 578.742 231.732 580.071C253.529 581.4 264.428 581.979 286.225 582.738C308.023 583.498 318.921 583.908 340.719 583.871C362.516 583.833 373.415 583.309 395.212 582.55C417.009 581.79 427.908 581.4 449.705 580.071C471.503 578.743 482.401 577.561 504.199 575.906C525.996 574.25 536.895 573.413 558.692 571.792C570.88 570.886 579.662 570.169 588.846 569.476C594.828 569.025 600.449 563.504 604.23 554.22L604.291 554.071C587.987 552.981 577.314 552.039 558.692 550.654C536.895 549.034 525.996 548.197 504.199 546.541Z" fill="black"/>
                </svg>
            </div>
        )
    } else {
        return (
            <div className={props.className} style={props.style}>
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      viewBox="0 0 114.08878 111.5" shapeRendering="geometricPrecision">
                  <path  d="M57.04406,0c4.40441,0,14.91066,0.76121,17.49313,1.59502c0.01482,0.00478,0.02963,0.00957,0.04444,0.01437
                      c-3.19217,0.03932-5.00922,0.04402-8.41418,0.07065c-3.64935,0.02855-5.47403,0.06308-9.12339,0.06308h-0.00013
                      c-3.64936,0-5.47403-0.03453-9.12339-0.06308c-3.40497-0.02663-5.22201-0.03133-8.41419-0.07065
                      c0.01481-0.0048,0.02963-0.00959,0.04445-0.01437C42.13327,0.76121,52.63951,0,57.04393,0L57.04406,0z"/>
                  <path  d="M38.79715,11.16739c3.64935,0.07613,5.47403,0.10221,9.12339,0.14235
                      c3.64935,0.04012,5.47403,0.05833,9.12339,0.05833c3.64935,0,5.47403-0.01189,9.12339-0.05833
                      c3.64935-0.04647,5.47403-0.09448,9.12338-0.17399c3.64936-0.07947,5.47404-0.13119,9.12342-0.22348
                      c3.02806-0.0766,4.80077-0.12738,7.40117-0.19441v0c-1.03362-0.79925-2.29469-1.24891-3.60082-1.28381
                      c-1.12843-0.03015-2.3129-0.06155-3.80035-0.09918c-3.64938-0.0923-5.47406-0.144-9.12342-0.22349
                      c-3.64935-0.07949-5.47403-0.12752-9.12338-0.17397c-3.64936-0.04646-5.47403-0.05833-9.12339-0.05833
                      c-3.64936,0-5.47403,0.01819-9.12339,0.05833c-3.64936,0.04014-5.47403,0.06622-9.12339,0.14234
                      c-3.64936,0.07612-5.47403,0.13966-9.12339,0.23829c-1.48151,0.04005-2.66244,0.07404-3.78677,0.10661
                      c-1.31092,0.03797-2.57606,0.49043-3.61361,1.29258l0,0c-0.00112,0.00087-0.00043,0.00267,0.00099,0.0027
                      c2.59941,0.07075,4.37197,0.12733,7.39939,0.20915C33.32312,11.02775,35.1478,11.09126,38.79715,11.16739z"/>
                  <path  d="M29.67377,20.48985c3.64935,0.14532,5.47403,0.25218,9.12339,0.36656
                      c3.64935,0.11439,5.47403,0.14848,9.12339,0.20532c3.64935,0.05687,5.47403,0.07898,9.12339,0.07898
                      c3.64935,0,5.47403-0.01537,9.12339-0.07898c3.64935-0.06359,5.47403-0.12461,9.12338-0.23899
                      c3.64936-0.11438,5.47404-0.19432,9.12342-0.3329c3.64935-0.13857,5.47403-0.22122,9.12338-0.35998
                      c3.11794-0.11857,4.90483-0.19323,7.6349-0.28511c-0.0034-0.00416-0.00678-0.00832-0.01017-0.01249
                      c-0.63395-0.77823-1.57659-1.24077-2.57965-1.27803c-1.53644-0.05708-3.0061-0.11583-5.04508-0.19336
                      c-3.64935-0.13879-5.47403-0.22143-9.12338-0.36001c-3.64938-0.13857-5.47406-0.21852-9.12342-0.33288
                      c-3.64935-0.11439-5.47403-0.17541-9.12338-0.23899c-3.64936-0.0636-5.47403-0.07898-9.12339-0.07898
                      c-3.64936,0-5.47403,0.02211-9.12339,0.07898c-3.64936,0.05685-5.47403,0.09094-9.12339,0.20533
                      c-3.64936,0.11437-5.47403,0.22125-9.12339,0.36655c-3.64935,0.14532-5.47403,0.22122-9.12339,0.36001
                      c-2.03902,0.07753-3.50867,0.13629-5.04511,0.19336c-1.00303,0.03726-1.94563,0.49978-2.57959,1.27795l-0.01023,0.01256
                      c2.7301,0.09188,4.51694,0.16654,7.63493,0.28511C24.19973,20.26863,26.02441,20.34455,29.67377,20.48985z"/>
                  <path  d="M20.55038,29.61165c3.64935,0.18638,5.47403,0.31705,9.12339,0.49292
                      c3.64935,0.17587,5.47403,0.24874,9.12339,0.38645c3.64935,0.13772,5.47403,0.22593,9.12339,0.30211
                      c3.64935,0.07617,5.47403,0.07876,9.12339,0.07876c3.64935,0,5.47403-0.00573,9.12339-0.07876
                      c3.64935-0.07303,5.47403-0.14235,9.12338-0.28638c3.64936-0.14404,5.47404-0.25475,9.12342-0.43378
                      c3.64935-0.17901,5.47403-0.27789,9.12338-0.46133c3.6493-0.18342,5.47404-0.29477,9.12339-0.4558
                      c1.83287-0.08087,3.20548-0.13926,4.58022-0.19211c-0.00219-0.0041-0.00438-0.00819-0.00657-0.01228
                      c-0.40109-0.74995-1.17322-1.22703-2.02294-1.26269c-0.76911-0.03228-1.59764-0.06824-2.55071-0.1103
                      c-3.64935-0.16103-5.47409-0.27237-9.12339-0.4558c-3.64935-0.18342-5.47403-0.28231-9.12338-0.46133
                      c-3.64938-0.17901-5.47406-0.28974-9.12342-0.43376c-3.64935-0.14404-5.47403-0.21335-9.12338-0.28638
                      c-3.64936-0.07303-5.47403-0.07877-9.12339-0.07877c-3.64936,0-5.47403,0.00259-9.12339,0.07877
                      c-3.64936,0.07619-5.47403,0.1644-9.12339,0.30211c-3.64936,0.13771-5.47403,0.21058-9.12339,0.38646
                      c-3.64935,0.17587-5.47403,0.30653-9.12339,0.49291c-3.64935,0.18639-5.47403,0.27794-9.12339,0.43897
                      c-0.95203,0.04201-1.77979,0.07908-2.54818,0.113c-0.84782,0.03743-1.61748,0.51429-2.01787,1.26255l-0.0186,0.0348
                      c1.37617,0.0571,2.74979,0.11975,4.58465,0.20072C15.07635,29.33372,16.90102,29.42527,20.55038,29.61165z"/>
                  <path  d="M20.55038,39.08212c3.64935,0.22231,5.47403,0.33891,9.12339,0.54644
                      c3.64935,0.20752,5.47403,0.33069,9.12339,0.49117c3.64935,0.16047,5.47403,0.22691,9.12339,0.31116
                      c3.64935,0.08427,5.47403,0.11327,9.12339,0.11014c3.64935-0.00315,5.47403-0.03844,9.12339-0.12586
                      c3.64935-0.08742,5.47403-0.15388,9.12338-0.3112c3.64936-0.15731,5.47404-0.2679,9.12342-0.47541
                      c3.64935-0.20753,5.47403-0.33674,9.12338-0.56221c3.6493-0.22549,5.47404-0.3663,9.12339-0.56521
                      c3.4265-0.18674,5.24458-0.27049,8.47471-0.40284c-0.00134-0.00414-0.00269-0.00828-0.00404-0.01242
                      c-0.2403-0.73704-0.91843-1.244-1.69296-1.27697c-2.27583-0.09689-3.9976-0.18061-6.77771-0.33214
                      c-3.64935-0.19891-5.47409-0.33971-9.12339-0.56519c-3.64935-0.22549-5.47403-0.35469-9.12338-0.56221
                      c-3.64938-0.20753-5.47406-0.3181-9.12342-0.47543c-3.64935-0.15731-5.47403-0.22378-9.12338-0.3112
                      c-3.64936-0.08741-5.47403-0.12271-9.12339-0.12585c-3.64936-0.00316-5.47403,0.02586-9.12339,0.11012
                      c-3.64936,0.08425-5.47403,0.15071-9.12339,0.31118c-3.64936,0.16048-5.47403,0.28365-9.12339,0.49118
                      c-3.64935,0.20752-5.47403,0.32411-9.12339,0.54642c-3.64935,0.22232-5.47403,0.36625-9.12339,0.56516
                      c-2.77798,0.15141-4.49923,0.23517-6.77246,0.33197c-0.77462,0.03299-1.45264,0.54009-1.69319,1.27715
                      c-0.00476,0.01458-0.00951,0.02916-0.01426,0.04375c3.23331,0.13247,5.05154,0.21626,8.47991,0.40313
                      C15.07635,38.71587,16.90102,38.85981,20.55038,39.08212z"/>
                  <path  d="M11.42699,47.79691c3.64935,0.22763,5.47403,0.38227,9.12339,0.63023s5.47403,0.38446,9.12339,0.60958
                      c3.64935,0.22511,5.47403,0.35213,9.12339,0.51598c3.64935,0.16381,5.47403,0.22049,9.12339,0.30319
                      c3.64935,0.08268,5.47403,0.11027,9.12339,0.11027c3.64935,0,5.47403-0.02129,9.12339-0.11027
                      c3.64935-0.08899,5.47403-0.16391,9.12338-0.33467c3.64936-0.17076,5.47404-0.29401,9.12342-0.51916
                      c3.64935-0.22514,5.47403-0.36547,9.12338-0.6065c3.6493-0.24103,5.47404-0.3805,9.12339-0.59865s5.47403-0.31847,9.12339-0.4921
                      c0.53825-0.02562,1.036-0.0494,1.50655-0.0718c-0.00204-0.01359-0.00408-0.02718-0.00613-0.04076
                      c-0.1064-0.70673-0.70097-1.23749-1.41486-1.27147c-0.02841-0.00135-0.05693-0.00271-0.08556-0.00407
                      c-3.64936-0.17365-5.47404-0.27397-9.12339-0.49212s-5.47409-0.3576-9.12339-0.59863
                      c-3.64935-0.24102-5.47403-0.38137-9.12338-0.60652c-3.64938-0.22513-5.47406-0.34839-9.12342-0.51916
                      c-3.64935-0.17076-5.47403-0.24568-9.12338-0.33467c-3.64936-0.08898-5.47403-0.11027-9.12339-0.11027
                      c-3.64936,0-5.47403,0.02759-9.12339,0.11027c-3.64936,0.08271-5.47403,0.13937-9.12339,0.30319
                      c-3.64936,0.16385-5.47403,0.29087-9.12339,0.51598c-3.64935,0.22511-5.47403,0.36164-9.12339,0.60959
                      c-3.64935,0.24795-5.47403,0.40259-9.12339,0.63021c-3.64935,0.22763-5.47403,0.33742-9.12339,0.50789
                      c-0.02901,0.00136-0.05791,0.0027-0.08669,0.00405c-0.71436,0.0333-1.30985,0.56402-1.41619,1.2712l-0.00185,0.01232
                      c0.47004,0.02107,0.96719,0.04388,1.50474,0.06899C5.95296,47.45949,7.77764,47.56929,11.42699,47.79691z"/>
                  <path  d="M57.04485,59.21397c3.64936,0,5.47403-0.04014,9.12339-0.12603c3.64935-0.08586,5.47403-0.1332,9.12338-0.30334
                      c3.64935-0.17013,5.47403-0.31598,9.12339-0.54731c3.64935-0.23134,5.47403-0.35733,9.12338-0.60938
                      c3.64935-0.25205,5.47404-0.42006,9.12339-0.65086c3.64935-0.23082,5.47403-0.32735,9.12338-0.50314
                      c0.54914-0.02644,1.75484-0.05079,2.23368-0.07347c0,0,0.07078-0.33333,0.06992-0.6679
                      c-0.00069-0.27172-0.07394-0.63971-0.07394-0.63971c-0.65147-0.03047-1.44606-0.06393-2.22966-0.10166
                      c-3.64935-0.17577-5.47403-0.27234-9.12338-0.50312c-3.64935-0.2308-5.47404-0.39882-9.12339-0.65088
                      s-5.47403-0.37803-9.12338-0.60938c-3.64936-0.23132-5.47404-0.3772-9.12339-0.54731
                      c-3.64935-0.17014-5.47403-0.21748-9.12338-0.30334c-3.64936-0.08589-5.47403-0.12603-9.12339-0.12603h-0.00092
                      c-3.64936,0-5.47403,0.04014-9.12339,0.12603c-3.64936,0.08586-5.47403,0.1332-9.12339,0.30334
                      c-3.64936,0.17011-5.47403,0.31599-9.12339,0.54731c-3.64935,0.23135-5.47403,0.35733-9.12339,0.60938
                      s-5.47403,0.42007-9.12339,0.65088c-3.64935,0.23079-5.47403,0.32735-9.12339,0.50312
                      c-0.7836,0.03773-1.57819,0.07119-2.22965,0.10166c0,0-0.07325,0.36799-0.07394,0.63971
                      c-0.00085,0.33456,0.06992,0.6679,0.06992,0.6679c0.47883,0.02269,1.68454,0.04703,2.23368,0.07347
                      c3.64935,0.17579,5.47403,0.27232,9.12339,0.50314c3.64935,0.2308,5.47403,0.39881,9.12339,0.65086
                      c3.64935,0.25205,5.47403,0.37804,9.12339,0.60938c3.64935,0.23133,5.47403,0.37719,9.12339,0.54731
                      c3.64935,0.17014,5.47403,0.21748,9.12339,0.30334c3.64935,0.08589,5.47403,0.12603,9.12339,0.12603H57.04485z"/>
                  <path  d="M102.66089,63.6682c-3.64935-0.22448-5.47409-0.37341-9.12339-0.61446
                      c-3.64935-0.241-5.47403-0.36874-9.12338-0.59071c-3.64938-0.22198-5.47406-0.35154-9.12342-0.51916
                      c-3.64935-0.16762-5.47403-0.22993-9.12338-0.3189c-3.64936-0.08899-5.47403-0.12288-9.12339-0.12603
                      c-3.64936-0.00316-5.47403,0.02443-9.12339,0.11026c-3.64936,0.08585-5.47403,0.1551-9.12339,0.31893
                      c-3.64936,0.16383-5.47403,0.27828-9.12339,0.50024s-5.47403,0.36162-9.12339,0.60959
                      c-3.64935,0.24795-5.47403,0.40259-9.12339,0.63023c-3.64935,0.22761-5.47403,0.33741-9.12339,0.50788
                      c-0.53754,0.02509-1.0347,0.0479-1.50474,0.06898c0.00072,0.00477,0.00143,0.00954,0.00215,0.01431
                      c0.1062,0.70601,0.70068,1.23586,1.41386,1.2691c0.02946,0.00137,0.05903,0.00275,0.08873,0.00414
                      c3.64935,0.17047,5.47403,0.28027,9.12339,0.50787c3.64935,0.22764,5.47403,0.38228,9.12339,0.63023
                      c3.64935,0.24797,5.47403,0.38763,9.12339,0.6096c3.64935,0.22196,5.47403,0.3364,9.12339,0.50024
                      c3.64935,0.16383,5.47403,0.23309,9.12339,0.31893c3.64935,0.08583,5.47403,0.11342,9.12339,0.11028
                      c3.64935-0.00315,5.47403-0.03703,9.12339-0.12603c3.64935-0.08899,5.47403-0.1513,9.12338-0.31892
                      c3.64936-0.16762,5.47404-0.29718,9.12342-0.51916c3.64935-0.22197,5.47403-0.34969,9.12338-0.59072
                      c3.6493-0.24104,5.47404-0.38997,9.12339-0.61445c3.64935-0.22444,5.47403-0.33423,9.12339-0.50787
                      c0.03017-0.00143,0.06023-0.00286,0.09015-0.00429c0.71261-0.03388,1.30632-0.56355,1.41241-1.26904l0.00185-0.01228
                      c-0.46995-0.02187-0.96698-0.04536-1.5044-0.07093C108.13492,64.00243,106.31024,63.89267,102.66089,63.6682z"/>
                  <path  d="M93.5375,72.39877c-3.64935-0.22234-5.47403-0.34209-9.12338-0.54648
                      c-3.64938-0.20438-5.47406-0.31808-9.12342-0.4754c-3.64935-0.15733-5.47403-0.22694-9.12338-0.31123
                      c-3.64936-0.08425-5.47403-0.11012-9.12339-0.11012c-3.64936,0-5.47403,0.03217-9.12339,0.11012
                      c-3.64936,0.07799-5.47403,0.12241-9.12339,0.27973c-3.64936,0.15731-5.47403,0.29936-9.12339,0.5069
                      c-3.64935,0.20751-5.47403,0.31153-9.12339,0.53071c-3.64935,0.21916-5.47403,0.36624-9.12339,0.56513
                      c-3.42837,0.18688-5.2466,0.27068-8.47991,0.40314c0.00805,0.02474,0.01612,0.04947,0.0242,0.0742
                      c0.23496,0.71861,0.8957,1.21321,1.65107,1.24535c2.28921,0.09739,4.01357,0.18121,6.80464,0.33334
                      c3.64935,0.19889,5.47403,0.34596,9.12339,0.56513c3.64935,0.21918,5.47403,0.3232,9.12339,0.53072s5.47403,0.34958,9.12339,0.5069
                      c3.64935,0.15732,5.47403,0.20174,9.12339,0.27972c3.64935,0.07796,5.47403,0.11012,9.12339,0.11012
                      c3.64935,0,5.47403-0.02586,9.12339-0.11012c3.64935-0.08428,5.47403-0.15389,9.12338-0.31123
                      c3.64936-0.15733,5.47404-0.27101,9.12342-0.4754c3.64935-0.20438,5.47403-0.32413,9.12338-0.54649
                      c3.6493-0.22232,5.47404-0.36298,9.12339-0.56519c2.79649-0.15495,4.52206-0.2471,6.81795-0.34847
                      c0.75398-0.03329,1.41333-0.52733,1.64748-1.24481l0.00394-0.01209c-3.22681-0.13369-5.04472-0.22925-8.46937-0.41899
                      C99.01154,72.76174,97.1868,72.62109,93.5375,72.39877z"/>
                  <path  d="M93.5375,81.86921c-3.64935-0.18659-5.47403-0.29492-9.12338-0.47709
                      c-3.64938-0.18217-5.47406-0.29668-9.12342-0.43376c-3.64935-0.13712-5.47403-0.1787-9.12338-0.25172
                      c-3.64936-0.07303-5.47403-0.11342-9.12339-0.11342c-3.64936,0-5.47403,0.03724-9.12339,0.11342
                      c-3.64936,0.07617-5.47403,0.13351-9.12339,0.26745c-3.64936,0.13394-5.47403,0.22638-9.12339,0.40225
                      c-3.64935,0.17586-5.47403,0.29051-9.12339,0.47712c-3.64935,0.18658-5.47403,0.29476-9.12339,0.4558
                      c-1.8329,0.08087-3.20554,0.13926-4.58023,0.19212c0.00574,0.01073,0.01148,0.02147,0.01722,0.0322
                      c0.3947,0.73747,1.15395,1.20678,1.98966,1.24183c0.77549,0.03252,1.61093,0.06878,2.57335,0.11124
                      c3.64935,0.16103,5.47403,0.26921,9.12339,0.4558c3.64935,0.18659,5.47403,0.30125,9.12339,0.47712s5.47403,0.26831,9.12339,0.40225
                      c3.64935,0.13396,5.47403,0.19128,9.12339,0.26745c3.64935,0.07618,5.47403,0.11342,9.12339,0.11342
                      c3.64935,0,5.47403-0.04039,9.12339-0.11342c3.64935-0.07303,5.47403-0.11463,9.12338-0.25172
                      c3.64936-0.13708,5.47404-0.25159,9.12342-0.43378c3.64935-0.18217,5.47403-0.29048,9.12338-0.47707
                      c3.6493-0.18657,5.47404-0.29794,9.12339-0.45581c0.96336-0.04168,1.79947-0.0767,2.57559-0.10776
                      c0.83734-0.03351,1.59874-0.503,1.994-1.24194l0.00647-0.01211c-1.37331-0.04962-2.74503-0.10482-4.57607-0.18404
                      C99.01154,82.16713,97.1868,82.05578,93.5375,81.86921z"/>
                  <path  d="M84.41412,90.991c-3.64938-0.13858-5.47406-0.23744-9.12342-0.34865
                      c-3.64935-0.11121-5.47403-0.14381-9.12338-0.2074c-3.64936-0.06361-5.47403-0.10741-9.12339-0.11057
                      c-3.64936-0.00314-5.47403,0.03118-9.12339,0.09477c-3.64936,0.0636-5.47403,0.11199-9.12339,0.2232
                      c-3.64936,0.11122-5.47403,0.19431-9.12339,0.3329c-3.64935,0.13857-5.47403,0.22121-9.12339,0.35998
                      c-3.11799,0.11858-4.90483,0.19325-7.63493,0.28512c0.00366,0.0045,0.00732,0.009,0.01099,0.01349
                      c0.63349,0.77757,1.57538,1.23975,2.57765,1.27699c1.53686,0.05708,3.00676,0.11585,5.04629,0.19341
                      c3.64935,0.13877,5.47403,0.22142,9.12339,0.35999s5.47403,0.22167,9.12339,0.33289
                      c3.64935,0.11122,5.47403,0.15961,9.12339,0.22321c3.64935,0.06358,5.47403,0.09792,9.12339,0.09476
                      c3.64935-0.00316,5.47403-0.04698,9.12339-0.11057c3.64935-0.06358,5.47403-0.09619,9.12338-0.20741
                      c3.64936-0.11121,5.47404-0.21006,9.12342-0.34863c3.64935-0.13859,5.47403-0.20863,9.12338-0.34425
                      c2.04046-0.07582,3.5108-0.13582,5.04847-0.19379c1.00153-0.03775,1.94256-0.49982,2.57562-1.27681l0.01015-0.01247
                      c-2.72971-0.09118-4.51655-0.17006-7.63425-0.28594C89.88815,91.19961,88.06347,91.12957,84.41412,90.991z"/>
                  <path  d="M75.2907,100.32935c-3.64935-0.08265-5.47403-0.12751-9.12338-0.17397
                      c-3.64936-0.04646-5.47403-0.05834-9.12339-0.05834c-3.64936,0-5.47403,0.01188-9.12339,0.05834
                      c-3.64936,0.04646-5.47403,0.09448-9.12339,0.17397c-3.64936,0.07949-5.47403,0.13118-9.12339,0.2235
                      c-3.02812,0.07659-4.8008,0.12737-7.4012,0.1944c0.01044,0.00808,0.02089,0.01615,0.03134,0.02422
                      c1.02256,0.78985,2.27451,1.22498,3.56614,1.25949c1.12942,0.03018,2.31477,0.0616,3.80372,0.09926
                      c3.64935,0.09232,5.47403,0.14401,9.12339,0.2235c3.64935,0.07949,5.47403,0.12751,9.12339,0.17396
                      c3.64935,0.04648,5.47403,0.05836,9.12339,0.05836c3.64935,0,5.47403-0.01188,9.12339-0.05836
                      c3.64935-0.04645,5.47403-0.09132,9.12338-0.17396c3.64936-0.08263,5.47404-0.14697,9.12342-0.23927
                      c1.48762-0.03763,2.6722-0.06641,3.80073-0.09345c1.2974-0.03109,2.55589-0.46634,3.58267-1.26003l0.01623-0.01254
                      c-2.59957-0.06332-4.3721-0.10322-7.39963-0.17981C80.76473,100.4763,78.94006,100.41199,75.2907,100.32935z"/>
                  <path  d="M57.04406,111.5c4.40441,0,14.91066-0.76121,17.49313-1.59502c0.01482-0.00478,0.02963-0.00957,0.04444-0.01437
                      c-3.19217-0.03931-5.00922-0.04401-8.41418-0.07065c-3.64935-0.02855-5.47403-0.06308-9.12339-0.06308h-0.00013
                      c-3.64936,0-5.47403,0.03453-9.12339,0.06308c-3.40497,0.02663-5.22201,0.03133-8.41419,0.07065
                      c0.01481,0.0048,0.02963,0.00959,0.04445,0.01437C42.13327,110.73879,52.63951,111.5,57.04393,111.5H57.04406z"/>
                  </svg>
          </div>
          )
    }
}

export default BwSymbol;